import React, { useEffect, useState } from "react";
import clineImg from "../../../../waveforms/cline.jpg";
import lineImg from "../../../../waveforms/line.jpg";
import p2pImg from "../../../../waveforms/p2p.jpg";
import pointImg from "../../../../waveforms/point.jpg";
import {
  removeWaveformData,
  saveWaveformData,
} from "../../../../actions/createPodreel";
import { connect, useSelector, useDispatch } from "react-redux";
import randomstring from "randomstring";

const WaveForm = (props) => {
  const dispatch = useDispatch();
  const wave = useSelector(
    (state) => state.createPodreel.customize.waveform.name
  );
  const [waveForm, setWaveForm] = useState({
    name: "",
    id: "",
    color: "#ffffff",
    posX: 0,
    posY: 0,
    width: 200,
    height: 100,
    isSelected: false,
  });

  useEffect(() => {
    setWaveForm(props.customize.waveform);
  }, [props.customize]);

  const showWaveform = (e) => {
    const localForm = { ...waveForm };
    localForm.name = e.target.name;
    localForm.id = randomstring.generate(7);
    localForm.isSelected = true;
    props.saveWaveformData(localForm);
  };
  const [radio, setRadio] = useState(false);

  const removeWaveform = (e) => {
    if (radio === true) {
      setRadio(false);
    } else {
      setRadio(true);
    }
  };

  useEffect(() => {
    if (wave !== "") {
      setRadio(false);
    }
  }, [wave]);

  useEffect(() => {
    const data = {
      name: "",
      id: "",
      color: "",
      posX: 0,
      posY: 0,
      width: 200,
      height: 100,
      isSelected: false,
    };
    if (radio === true) {
      dispatch(removeWaveformData(data));
    }
  }, [radio]);

  return (
    <div
      className="tab-pane fade"
      id="v-pills-profile"
      role="tabpanel"
      aria-labelledby="v-pills-profile-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="general-content">
            <div className="row">
              <div className="col-md-12">
                <div className="dimension mb-4">
                  <span className="d-block">
                    Choose a Waveform for your Podcast
                  </span>
                  <div className="row mt-1" id="wave">
                    <div className="col-12 col-sm-6 col-md-3">
                      {/* <div className="wavefrom-box " />  */}
                      {/* style={waveForm.name === "cline" ? {border:"1px solid #fff"} : null} */}
                      <img
                        src={clineImg}
                        alt=""
                        onClick={showWaveform}
                        name="cline"
                        style={
                          wave === "cline" ? { border: "1px solid #fff" } : null
                        }
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                      <img
                        src={lineImg}
                        alt=""
                        onClick={showWaveform}
                        name="line"
                        style={
                          wave === "line" ? { border: "1px solid #fff" } : null
                        }
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                      <img
                        src={p2pImg}
                        alt=""
                        onClick={showWaveform}
                        name="p2p"
                        style={
                          wave === "p2p" ? { border: "1px solid #fff" } : null
                        }
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-3">
                      <img
                        src={pointImg}
                        alt=""
                        onClick={showWaveform}
                        name="point"
                        style={
                          wave === "point" ? { border: "1px solid #fff" } : null
                        }
                      />
                    </div>
                  </div>
                  <span
                    className="remove"
                    style={{ top: "", left: "" }}
                  >
                    <div
                      className="form-check form-switch"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        value={radio}
                        onChange={(e) => removeWaveform(e)}
                        checked={radio}
                        disabled={wave === "" ? true : false}
                        style={{marginRight:"88px"}}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                        style={{
                          margin: "0",
                          paddingLeft: "2%",
                          paddingTop: "1%",
                          color:"#fff",
                          opacity:"1",
                          fontSize:"15px"
                        }}
                      >
                        Remove
                      </label>
                    </div>
                  </span>
                  {/* <div className="row mt-5">
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />
                                      </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customize: state.createPodreel.customize,
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveWaveformData: (data) => dispatch(saveWaveformData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WaveForm);
