import React from 'react';
import { Link } from "react-router-dom";

function CreateHead(props) {

  return (
    <>
      <section className="create-head-sec my-create-head-sec">
        <div className="container" style={{ padding: "10px 0" }} >
          <div className="row px-2 px-md-4">
            <div className="col-6 d-flex align-items-center">
              <div className="create-prodcast-head">
                <ul className="list-inline m-0">
                  <li> Create Podcast</li>
                </ul>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <div className="create-head-input d-flex justify-content-end align-items-center">
                <div className="createvideo-btn">
                  <Link to="/my-podcast">My Podcast</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default CreateHead
