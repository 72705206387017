import React from "react";

const SocialNetwork = (props) => {
  return (
    <>
      <li
        style={{
          backgroundColor: " #060818",
          padding: "2%",
          borderRadius: "11%",
        }}
      >
        <a>
          <div className="img-wrraper">
            <img src={props.icon} alt="facebook" width="56px" />
          </div>
          <p className="mt-2 m-0">{props.name}</p>
        </a>
      </li>
    </>
  );
};

export default SocialNetwork;
