import React, { useState, useEffect } from "react";
import styled, { isStyledComponent } from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";
import { useHistory } from "react-router-dom";
import {
  setSmtpData,
  clearWhiteLabelData,
  getWhiteLabelUsers,
} from "../../actions/whiteLabel";
import axios from "axios";
import { baseURL } from "../../global/global";

const SMTP = ({
  user,
  loading,
  whiteLabel,
  setSmtpData,
  clearWhiteLabelData,
  getWhiteLabelUsers,
}) => {
  const [accountType, setAccountType] = useState([]);
  const [smtp, setSmtp] = useState({
    host: "",
    username: "",
    password: "",
    port: "",
    sent_from: "",
  });
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (
      !(
        Object.keys(whiteLabel.smtp).length === 0 &&
        whiteLabel.smtp.constructor === Object
      )
    ) {
      setSmtp({ ...whiteLabel.smtp });
    }
  }, [whiteLabel.smtp]);

  useEffect(() => {
    if (whiteLabel.whiteLabelData.length > 0) {
      setSmtp({ ...whiteLabel.whiteLabelData[0].smtp });
    }
  }, [whiteLabel.whiteLabelData]);

  const setAllSmtpValue = (e) => {
    let target = e.target;
    setSmtp((value) => ({ ...value, [target.name]: target.value }));
  };

  const sendSmtpData = async () => {
    setSmtpData(smtp);
  };

  const sendAllDataToBackend = async () => {
    setLoader(true);

    let price = whiteLabel.price.map((value) => ({
      id: value.id,
      name: value.name,
      price: value.price,
    }));
    let payment = whiteLabel.payment.map((value) => ({
      mem_id: value.mem_id,
      type: value.payment,
      button_code: value.button_code,
      secret_key: "",
      product_id: "",
      ipn_url: "",
    }));

    // const formData = new FormData();

    // formData.append("general", whiteLabel.general);
    // formData.append("price", price);
    // formData.append("payment", payment);
    // formData.append("smtp", whiteLabel.smtp);
    // formData.append("user_id", user.user_id);
    // formData.append("template_id", whiteLabel.template_id);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      general: { ...whiteLabel.general },
      price: price,
      payment: payment,
      smtp: whiteLabel.smtp,
      user_id: user.user_id,
      template_id: whiteLabel.template_id,
    });

    try {
      const res = await axios.post(`${baseURL}api/add-domain`, body, config);

      if (res.data.status === true) {
        setLoader(false);
        clearWhiteLabelData();
        getWhiteLabelUsers({ user_id: user.user_id });
        history.push("/whitelabel");
      } else if (res.data.status === false) {
        setLoader(false);
        history.push("/whitelabel");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"smtp"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  SMTP Details
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-10">
                    <div className="row justify-content-center">
                      {/* <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Domain Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="domain"
                            placeholder="Enter your Domain Name"
                            value={smtp.domain}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div> */}

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Host Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="host"
                            placeholder="Enter your Host Name"
                            value={smtp.host}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Username </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="username"
                            placeholder="Enter your Username"
                            value={smtp.username}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Password </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="password"
                            name="password"
                            placeholder="Enter your Password"
                            value={smtp.password}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Port </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="port"
                            placeholder="Enter your Port No."
                            value={smtp.port}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Sent From </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="sent_from"
                            placeholder="Sent From"
                            value={smtp.sent_from}
                            onChange={(e) => setAllSmtpValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-11 col-md-8 col-lg-4 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={sendSmtpData}
                            disabled={
                              smtp.host === "" ||
                              smtp.sent_from === "" ||
                              smtp.username === "" ||
                              smtp.password === "" ||
                              smtp.port === ""
                                ? true
                                : false
                            }
                          >
                            Save
                          </button>
                        )}
                      </div>

                      <div className="col-11 col-md-8 col-lg-4 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={sendAllDataToBackend}
                            disabled={
                              smtp.host === "" ||
                              smtp.sent_from === "" ||
                              smtp.username === "" ||
                              smtp.password === "" ||
                              smtp.port === "" ||
                              (Object.keys(whiteLabel.smtp).length === 0 &&
                                whiteLabel.smtp.constructor === Object)
                                ? true
                                : false
                            }
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, {
  setSmtpData,
  clearWhiteLabelData,
  getWhiteLabelUsers,
})(SMTP);
