import { FETCH_DASHBOARD_DATA } from "./Types";

import axios from "axios";
import { baseURL } from "../global/global";

export const fetchDashboardData = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ user_id: data });

  try {
    const res = await axios.post(`${baseURL}api/podcast`, body, config);
    if (res.data.status === true) {
      dispatch({
        type: FETCH_DASHBOARD_DATA,
        payload: res.data.data.podcast,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
