import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
//step-wise component 1,2,3
import Upload from "./Upload";
// import AudioSection from "./AudioSectionOld";
import Customize from "./Customize";
import StepProgress from "./StepProgress";
import Createpod from "./Createpod";
import CreateHead from "./CreateHead";
import UploadSection from "./UploadSection";
import ProfileSection from "./ProfileSection";
import { useDispatch } from "react-redux";
import { resetData } from "../../actions/createPodreel";

// progress bar
const CreatePodcast = () => {
  const dispatch = useDispatch();
  const [showComponent, setshowComponent] = useState({
    upload: true,
    audio: false,
    customize: false,
  });
  useEffect(() => {
    dispatch(resetData());
  }, []);
  return (
    <>
      <Helmet>
        <title> PodReel | CreatePodcast</title>
      </Helmet>
      {/* <CreateHead title={"Create"} /> */}
      <UploadSection />
    </>
  );
};

export default CreatePodcast;
