import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { setTemplateIdData } from "../../actions/whiteLabel";
import { useHistory, Redirect } from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";

const Temp = ({ user, loading, whiteLabel, setTemplateIdData }) => {
  const [accountType, setAccountType] = useState([]);
  const [temp, setTemp] = useState([]);
  const [template_id, setTemplateId] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (whiteLabel.template.length > 0) {
      setTemp(whiteLabel.template);
    }
  }, [whiteLabel.template, whiteLabel.loading]);

  useEffect(() => {
    if (whiteLabel.template_id !== "") {
      setTemplateId(whiteLabel.template_id);
    } else if (whiteLabel.whiteLabelData.length > 0) {
      setTemplateId(whiteLabel.whiteLabelData[0].template_id);
    }
  }, [whiteLabel.template_id, whiteLabel.whiteLabelData]);

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }
  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"temp"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Template
                </h6>
                <div className="row bg-table mb-3 justify-content-center">
                  <div className="col-11">
                    <div className="row justify-content-center">
                      {temp.length <= 0
                        ? null
                        : temp.map((value) => (
                            <div
                              key={value.id}
                              className="col colmn-3 col-xl-3 col-md-6 col-sm-9 col-12 px-2 mb-lg-0 mb-5 mt-3"
                            >
                              <div className="card-wrapper">
                                <div
                                  className="status w-100 d-flex align-items-center mb-1 temp_video"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    alt=""
                                    className="proj_img poster"
                                    src={value.preview}
                                    // onClick={() =>
                                    //   previewVideo(template.preview_video_url)
                                    // }
                                    style={{
                                      cursor: "pointer",
                                      width: "100%",
                                      height: "15rem",
                                      objectFit: "cover",
                                    }}
                                  />

                                  {template_id === value.id ? (
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",

                                        width: "50px",

                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontFamily: "SourceSansPro",
                                      }}
                                    >
                                      <img
                                        src="./assets/images/tik.png"
                                        alt=""
                                      />
                                    </span>
                                  ) : null}
                                  <div id="overlay">
                                    <div id="overlay-content">
                                      <div className="details-wrapper row mx-0 justify-content-center">
                                        <div className="icon-wrapper pr-3">
                                          <a
                                            className="action-icon edit-icon mb-1 custom-ico-bg"
                                            title="Play"
                                            style={{
                                              background: "none",
                                              border: "none",
                                              cursor: "pointer",
                                            }}
                                            href={value.preview}
                                            target="_blank"
                                          >
                                            {/* <img
                                              width="25"
                                              height="25"
                                              src="./assets/images/play-button.svg"
                                              alt="Icon"
                                              // onClick={() =>
                                              //   previewVideo(
                                              //     template.preview_video_url
                                              //   )
                                              // }
                                            /> */}
                                            <i
                                              class="fa fa-eye"
                                              aria-hidden="true"
                                              style={{ fontSize: "25px" }}
                                            ></i>
                                          </a>
                                          <p className="sm-txt text-white">
                                            View
                                          </p>
                                        </div>

                                        <div className="icon-wrapper">
                                          <a
                                            href="!#"
                                            className="action-icon edit-icon mb-1 custom-ico-bg"
                                            style={{
                                              background: "none",
                                              border: "none",
                                              color: "#fff",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setTemplateId(value.id);
                                            }}
                                          >
                                            <i
                                              class="fa fa-check"
                                              aria-hidden="true"
                                              style={{ fontSize: "25px" }}
                                            ></i>
                                            {/* <svg
                                              width="25px"
                                              height="25px"
                                              viewBox=" 0 0 20 20"
                                            >
                                              <use xlinkHref="./assets/images/edit.svg#edit-ico" />
                                            </svg> */}
                                          </a>
                                          <p className="sm-txt text-white">
                                            Select
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="details-wrapper row mx-0">
                                  <div className="meta-wrapper flex-grow-1">
                                    <div className="date mb-1 d-flex justify-content-center">
                                      <span className="sm-txt text-white">
                                        {value.name}

                                        {/* <span className="pt-3 text-white">
                              #{template.template_name}Tags Ipsum
                            </span>
                          </span>
                          <span className="sm-txt text-white pt-2"> 90s </span> */}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-11 col-md-8 col-lg-4 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={() => {
                              setTemplateIdData(template_id);
                              return history.push(`/funnel`);
                            }}
                            disabled={template_id === "" ? true : false}
                          >
                            Save & Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }

  #overlay {
    height: 0%;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.7);
    overflow-y: hidden;
    transition: 0.5s;
  }

  #overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .temp_video:hover #overlay {
    height: 100%;
    position: realtive;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setTemplateIdData })(Temp);
