import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { removeAlert, setAlert } from "../../actions/alert";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
//step-wise component 1,2,3
import Upload from "./Upload";

import styled from "styled-components";

// progress bar
const StepProgress = ({ setShowStep, createPodreel }) => {
  return (
    <div>
      <div
        className='row d-flex justify-content-center align-items-center'
        style={{ height: "20vh" }}
      >
        <WhitelabelStyle>
          <div className='col-12 p-0'>
            <ul id='breadcrumb'>
              <li>
                {/* {
                is_client === true ? null : accountType.includes("business") ||
                  accountType.includes("bundle") ? ( */}
                <a
                  href='!#'
                  className={
                    ""
                    //   window.location.pathname === "/whitelabel"
                    //     ? "selective"
                    //     : "nothingchange"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setShowStep((value) => {
                      return { upload: true, audio: false, customize: false };
                    });
                  }}
                >
                  Upload <span class='icon icon-double-angle-right'></span>
                </a>
                {/* ) : null} */}
              </li>

              <li>
                {/* {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("dfy") ? ( */}
                <a
                  className={
                    ""
                    //   window.location.pathname === "/payment"
                    //     ? "selective"
                    //     : "nothingchange"
                  }
                  href='!#'
                  onClick={(e) => {
                    e.preventDefault();
                    if (createPodreel.audio_file_url !== "") {
                      setShowStep((value) => {
                        return { upload: false, audio: true, customize: false };
                      });
                    }
                  }}
                >
                  Audio <span class='icon icon-double-angle-right'></span>
                </a>
                {/* // ) : null} */}
              </li>
              <li>
                {/* {is_client === true ? null : accountType.includes("business") ||
                  accountType.includes("bundle") ? ( */}
                <a
                  className={
                    ""
                    //   window.location.pathname === "/smtp"
                    //     ? "selective"
                    //     : "nothingchange"
                  }
                  style={{ marginRight: "0px" }}
                  href='!#'
                  onClick={(e) => {
                    e.preventDefault();
                    // if (createPodreel.audio_file_url !== "") {
                    setShowStep((value) => {
                      return { upload: false, audio: false, customize: true };
                    });
                    // }
                  }}
                >
                  Customize <span class='icon icon-double-angle-right'></span>
                </a>
                {/* ) : null} */}
              </li>
            </ul>

            {/* <div class="row text-right d-flex justify-content-between pt-3">           
            <div className="btn btn-primary" onClick={setPrev}>
              Prev
            </div>
             
             <div className="btn btn-primary" onClick={setNext}>
              Next
            </div>
            </div> */}
          </div>
        </WhitelabelStyle>
      </div>
    </div>
  );
};

const WhitelabelStyle = styled.div`
  a,
  span {
    transition: none !important;
  }
`;

const mapStateToProps = (state) => ({
  createPodreel: state.createPodreel,
});

export default connect(mapStateToProps)(StepProgress);
