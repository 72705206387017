import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../../../global/global";
import { saveImageData } from "../../../../actions/createPodreel";
import randomstring from "randomstring";
import SweetAlert from "react-bootstrap-sweetalert";
import InfiniteScroll from "react-infinite-scroll-component";

const Image = (props) => {
  const [images, setImages] = useState([]);
  const userId = useSelector((state) => state.auth.user.user_id);
  const [showDelete, setShowDelete] = useState(false);
  const [pageNO, setPageNo] = useState(1);
  const [idx, setIndex] = useState("");
  const [uploadImage, setUploadImage] = useState({
    url: "",
    thumbnail: "",
    uploaded: false,
    id: 0,
  });
  const [mediaType, setMediaType] = useState("image");
  const [fetUploadedImage, setFetchUploadedImage] = useState([]);
  const [input, setInput] = useState({
    changeInput: false,
    keyword: "",
  });
  const [loader, setLoader] = useState(false);
  const [deleteData, setDeleteData] = useState({
    url: "",
    id: "",
    deleteId: "",
  });

  const handleChange = (e) => {
    setInput({
      changeInput: true,
      keyword: e.target.value,
    });
  };

  useEffect(() => {
    fetchImages();
  }, [pageNO]);

  function fetchImages() {
    if (input.keyword !== "") {
      setLoader(true);
      const data = {
        keyword: input.keyword,
        page_number: pageNO,
        type: mediaType,
      };
      axios.post(`${baseURL}api/load-library-images`, data).then((res) => {
        if (res.data.status === true) {
          setImages([...images, ...res.data.data]);
          setLoader(false);
        }
      });
    }
  }

  useEffect(() => {
    setImages([]);
  }, [mediaType]);

  const handleImageSubmit = (event) => {
    setImages([]);
    event.preventDefault();
    setPageNo(1);
    fetchImages();
  };

  const fetchUploadedMedia = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("user_id", userId);
    axios.post(`${baseURL}api/user-media`, formData).then((res) => {
      if (res.data.status === true) {
        setLoader(false);
        const arr = [];
        res.data.data.userassets.map((data) => {
          return arr.push(data.url);
        });

        // setFetchUploadedImage(fetUploadedImage.concat(arr));

        setFetchUploadedImage(res.data.data.userassets);

        // setUploadImage({
        //   ...uploadImage,
        //   uploaded: true,
        //   url: res.data.data.url,
        //   thumbnail: res.data.data.url,
        // });
      } else if (res.data.status === false) {
        // alert("Image not found");
        setLoader(false);
      }
    });
  };

  const handleUpload = (e) => {
    const bytes = e.target.files[0].size;
    const extension = e.target.files[0].name.split(".").pop();
    setLoader(true);

    if (bytes > 5242880) {
      swal(
        "Image file is too large!",
        "Please select an image file not more than 5Mb."
      ).then();
    } else {
      if (extension === "png" || extension === "jpg" || extension === "jpeg") {
        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("image", e.target.files[0]);

        axios.post(`${baseURL}api/image-upload`, formData).then((res) => {
          if (res.data.status === true) {
            // setFetchUploadedImage([...fetUploadedImage, res.data.data.url]);
            fetchUploadedMedia();
            setUploadImage({
              ...uploadImage,
              uploaded: true,
              url: res.data.data.url,
              thumbnail: res.data.data.url,
            });
            setLoader(false);
          } else if (res.status !== 200) {
            // alert("Image not found");
            setLoader(false);
          }
        });
      } else if (extension === "mp4") {
        setLoader(true);
        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("video", e.target.files[0]);

        axios.post(`${baseURL}api/video-upload`, formData).then((res) => {
          if (res.data.status === true) {
            // setFetchUploadedImage([...fetUploadedImage, res.data.data.url]);
            fetchUploadedMedia();
            setUploadImage({
              ...uploadImage,
              uploaded: true,
              url: res.data.data.url,
              thumbnail: res.data.data.url,
            });
            setLoader(false);
          } else if (res.status !== 200) {
            // alert("Image not found");
          }
        });
      } else {
        swal(
          "This file is not supported!",
          "Please select .png,.jpg or .jpeg audio file."
        ).then();
      }
    }
  };

  const onCancel = () => {
    setShowDelete(false);
  };

  const showPopup = (u, i, id) => {
    setShowDelete(true);
    setDeleteData({ url: u, id: i, deleteId: id });
  };

  const deleteImage = () => {
    setLoader(true);
    let array = [...fetUploadedImage];
    array.splice(deleteData.id, 1);
    setFetchUploadedImage(array);
    setShowDelete(false);

    const formData = new FormData();
    formData.append("user_id", userId);
    formData.append("id", deleteData.deleteId);

    axios.post(`${baseURL}api/delete-upload-file`, formData).then((res) => {
      if (res.data.status === true) {
        setLoader(false);
        fetchUploadedMedia();
      } else if (res.data.status === false) {
        // alert("Image not found");
        setLoader(false);
      }
    });
  };

  const handleAddImage = (image, index) => {
    let id = randomstring.generate(7);
    setIndex(index);
    const data = [
      {
        url: image,
        id: id,
        width: "",
        height: "",
        posx: "",
        posy: "",
        left: "",
        isSelected: false,
      },
    ];
    props.saveImageData(data);
  };

  const changeMediaType = (e) => {
    setMediaType(e.target.value);
  };

  useEffect(() => {
    fetchUploadedMedia();
  }, []);

  return (
    <div
      className="tab-pane fade"
      id="v-pills-messages"
      role="tabpanel"
      aria-labelledby="v-pills-messages-tab"
    >
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Delete"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="light"
        title="Do you want to delete this?"
        onConfirm={() => deleteImage()}
        onCancel={() => onCancel()}
        focusCancelBtn
        show={showDelete}
        
      >
        Do you want to delete this?
      </SweetAlert>

      <div className="images-box row mb-5 mb-lg-0" style={{ justifyContent: "center" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="library-upload mt-4 ">
              <ul
                className="nav nav-pills d-flex justify-content-center mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link mr-3 active"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                    style={{ padding: "10px 10px" }}
                  >
                    LIBRARY
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                    style={{ padding: "10px 10px" }}
                  >
                    UPLOAD
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="search-bar mt-4 ">
                    <form method="POST" onSubmit={handleImageSubmit}>
                      <div className="input-group w-75 mx-auto mb-3 ">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={input.keyword}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            id="basic-addon22"
                            onClick={handleImageSubmit}
                          >
                            <i className="fas fa-search"> </i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="select-media">
                    <div className="form-check" style={{ marginRight: "5%" }}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultValue="image"
                        defaultChecked
                        onChange={(e) => changeMediaType(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        Images
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        defaultValue="video"
                        onChange={(e) => changeMediaType(e)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Video
                      </label>
                    </div>
                  </div>

                  <div
                    id="scrollableDiv scrollableDiv-height"
                    style={{ overflowY: "scroll", height: "175px" }}
                  >
                    <InfiniteScroll
                      dataLength={images.length} //This is important field to render the next data
                      next={() => setPageNo(pageNO + 1)}
                      hasMore={true}
                      loader={loader}
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      style={{ paddingBottom: "10px" }}
                    >
                      <div className="row g-1">
                        {images.map((image, index) => (
                          <div className="col-6 col-md-4 mb-2" key={index}>
                            <div className="my-media-img-wrap" style={{minHeight:"150px", height:"180px"}}>
                            <img
                              key={image.name}
                              src={image.thumbnail}
                              alt={image.name}
                              loading="lazy"
                              className={`${idx === index ? "addBorder" : ""}`}
                              style={{
                                objectFit: "cover",
                                cursor: "pointer",
                                height:"100%"
                              }}
                              onClick={() => handleAddImage(image.url, index)}
                            />
                            </div>
                          </div>
                        ))}

                        {loader ? (
                          <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>

                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div
                    className="gallary"
                    // style={
                    //   fetUploadedImage.length > 0
                    //     ? { display: "flex" }
                    //     : { display: "none" }
                    // }
                  >
                    <div className="container">
                      <div className="row">
                      {loader ? (
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      fetUploadedImage.map((u, i) => {
                        return (
                          <div className="col-4 col-sm-3 mb-3">
                          <div className="gallary-images text-center">
                            <span onClick={() => showPopup(u.url, i, u.id)}>
                              <i class="far fa-trash-alt"></i>
                            </span>
                            {/* {
                              let ext = u.match(/\.([^\./\?]+)($|\?)/)[1]
                            } */}
                            {/* <span onClick={() => deleteImage(u,i)}><i class="far fa-trash-alt"></i></span> */}
                            {u.url.match(/\.([^\./\?]+)($|\?)/)[1] === "mp4" ? (
                              <video
                                src={u.url}
                                alt=""
                                style={{
                                  width: "100%",
                                  margin: "",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                className={`${idx === i ? "addBorder" : ""}`}
                                onClick={() => handleAddImage(u.url, i)}
                              />
                            ) : (
                              <img
                                src={u.url}
                                alt=""
                                style={{
                                  width: "100%",
                                  margin: "",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                className={`${idx === i ? "addBorder" : ""}`}
                                onClick={() => handleAddImage(u.url, i)}
                              />
                            )}
                          </div>
                          </div>
                        );
                      })
                    )}
                      </div>
                    </div>
                    
                  </div>

                  <label htmlFor="upload-img" style={{ width: "100%" }}>
                    <div
                      className="uplaod-sec mt-5 text-white d-flex justify-content-center align-items-center flex-column"
                      style={{ cursor: "pointer" }}
                    >
                      <label htmlFor="upload-img">
                        <span>
                          <i
                            className="fas fa-cloud-upload-alt"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                          </i>
                        </span>
                      </label>
                      <input
                        type="file"
                        name="upload-img"
                        id="upload-img"
                        accept="video/*,image/*"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                      />
                      <h6 className="text-white">Upload Image/Video File</h6>
                      <h6 style={{ color: "#fff" }}>
                        JPEG, JPG & PNG up to 5MB is allowed.
                      </h6>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customize: state.createPodreel.customize,
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveImageData: (data) => dispatch(saveImageData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Image);
