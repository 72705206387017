import React, { useState, useEffect } from "react";
import Moveable from "react-moveable";
import { imgPos } from "../../../actions/createPodreel";
import { useDispatch } from "react-redux";

const ImageMovable = (props) => {
  const dispatch = useDispatch();
  const [pos, setPos] = useState({
    posX: 10,
    posY: 10,
    width: "300",
    height: "200",
  });
  const [frame, setFrame] = useState({
    translate: [0, 0],
    transformOrigin: "50% 50%",
  });

  useEffect(() => {
    dispatch(imgPos(pos));
  }, [pos]);

  return (
    <>
      <Moveable
        // target={props.t}
        originDraggable={true}
        originRelative={true}
        draggable={true}
        resizable={true}
        throttleDrag={0}
        zoom={1}
        origin={true}
        padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
        rotationPosition={"top"}
        onDragOriginStart={(e) => {
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        onDragOrigin={(e) => {
          frame.translate = e.drag.beforeTranslate;
          frame.transformOrigin = e.transformOrigin;
        }}
        onDragStart={(e) => {
          e.set(frame.translate);
        }}
        onDrag={(e) => {
          frame.translate = e.beforeTranslate;
          setPos({ ...pos, posX: e.translate[0], posY: e.translate[1] });
        }}
        onRender={(e) => {
          const { translate, transformOrigin } = frame;
          e.target.style.transformOrigin = transformOrigin;
          e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px)`;
        }}
        onResizeStart={(e) => {
          e.setOrigin(["%", "%"]);
          e.dragStart && e.dragStart.set(frame.translate);
        }}
        onResize={(e) => {
          const beforeTranslate = e.drag.beforeTranslate;

          frame.translate = beforeTranslate;
          e.target.style.width = `${e.width}px`;
          e.target.style.height = `${e.height}px`;
          e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          setPos({ ...pos, width: e.width, height: e.height });
        }}
      />
    </>
  );
};

export default ImageMovable;
