import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";
import { useHistory } from "react-router-dom";
import { setPaymentData } from "../../actions/whiteLabel";

const Payment = ({ user, loading, whiteLabel, setPaymentData }) => {
  const [accountType, setAccountType] = useState([]);
  const [allPaymentOption, setAllPaymentOption] = useState([]);
  const [selectPayment, setSelectPayment] = useState("-Select-");
  const [paymentInputData, setPaymentInputData] = useState([
    { button_code: "", type: "bronze" },
    { button_code: "", type: "silver" },
    { button_code: "", type: "gold" },
  ]);
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (whiteLabel.paymentMethods.length > 0) {
      setAllPaymentOption(whiteLabel.paymentMethods);
      // setSelectPayment(whiteLabel.paymentMethods[0].name);
    }
  }, [whiteLabel.paymentMethods, whiteLabel.loading]);

  useEffect(() => {
    if (whiteLabel.whiteLabelData.length > 0) {
      setSelectPayment(whiteLabel.whiteLabelData[0].payment[0].type);
    }
  }, [whiteLabel.whiteLabelData]);

  useEffect(() => {
    whiteLabel.payment.length > 0 &&
      whiteLabel.payment.map((val) =>
        setPaymentInputData((pay) =>
          pay.map((payData) => {
            if (payData.type === val.type) {
              return { ...payData, button_code: val.button_code };
            } else {
              return { ...payData };
            }
          })
        )
      );
  }, [whiteLabel.payment]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  const sendPaymentData = () => {
    let data = [];

    whiteLabel.price.length > 0 &&
      whiteLabel.price.map((val) =>
        paymentInputData.map((pay) => {
          if (pay.type === val.type) {
            data = [
              ...data,
              { ...pay, payment: selectPayment, mem_id: val.id },
            ];
          }
        })
      );

    setPaymentData(data);
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"payment"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Payment
                </h6>
                <div className="row bg-table justify-content-center">
                  <div className="col-10">
                    <div className="row justify-content-center">
                      <div className="col col-lg-5 col-12 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Payment Mode</label>
                          <select
                            className="form-control input-account-type p-accnt"
                            id="Account_selector"
                            name="voice"
                            value={selectPayment}
                            onChange={(e) => {
                              setSelectPayment(e.target.value);
                            }}
                          >
                            <option value="-Select-" id="yt_video">
                              -Select-
                            </option>
                            {allPaymentOption.length > 0
                              ? allPaymentOption.map((payment) => {
                                  return (
                                    <option
                                      value={payment.name}
                                      key={payment.id}
                                    >
                                      {payment.name.toLowerCase()}
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>

                    {whiteLabel.price.length > 0 &&
                      whiteLabel.price.map((value, index) => (
                        <div
                          key={index}
                          className="row justify-content-center align-items-start"
                        >
                          <div
                            className="col-2 text-white"
                            style={{ fontSize: "1.2rem" }}
                          >
                            {value.name}:
                          </div>
                          <div className="col-6 p-1">
                            <div className="form-group form-group-mb">
                              <textarea
                                className="form-control input-dark-accnt personal-info"
                                type="text"
                                name="text"
                                placeholder="Button code"
                                value={
                                  paymentInputData.find(
                                    (data) => value.type === data.type
                                  ).button_code
                                }
                                onChange={(e) => {
                                  const target = e.target;
                                  setPaymentInputData((pay) =>
                                    pay.map((val) =>
                                      val.type === value.type
                                        ? { ...val, button_code: target.value }
                                        : { ...val }
                                    )
                                  );
                                }}
                                autoComplete="off"
                                required
                                style={{
                                  padding: "10px",
                                  height: "8rem",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-4 p-1">
                            <div className="form-group form-group-mb">
                              <label className="input-label">
                                Redirect Url
                              </label>
                              <input
                                className="form-control input-dark-accnt personal-info  w-100"
                                type="text"
                                name="secret_key"
                                placeholder="Enter Secret Key"
                                value="https://video.reelapps.io"
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="row justify-content-center">
                      <div className="col-11 col-md-8 col-lg-4 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={() => {
                              sendPaymentData();
                              return history.push(`/smtp`);
                            }}
                            disabled={
                              selectPayment === "-Select-" ? true : false
                            }
                          >
                            Save & Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setPaymentData })(Payment);
