import React, { useEffect } from "react";
import SocialNetwork from "./SocialNetwork";

import youtubeIcon from "../../assets/images/YT-DEFAULT.png";
// import youtubeDisable from "../../assets/images/YT_DISABLE.png";

// import { useDispatch, useSelector } from "react-redux";
// import {fetchSocialAccounts} from "../../actions/socialAction";

const IntegrationHead = () => {
  //   const dispatch = useDispatch();

  // const socialData = useSelector(state => state.social);

  // useEffect(()=>{
  //     dispatch(fetchSocialAccounts());
  // },[])

  return (
    <>
      <section className="integration-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="integration-head text-white text-center">
                <h1 style={{ color: "#fff" }}>Integrations</h1>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12 mx-auto">
              <div className="integration-box">
                <ul className="list-inline d-flex  flex-wrap justify-content-center">
                  <SocialNetwork
                    // icon={
                    //   socialData.youtube.length > 0
                    //     ? youtubeIcon
                    //     : youtubeDisable
                    // }
                    icon={youtubeIcon}
                    name="YouTube"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationHead;
