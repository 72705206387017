import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { connect } from "react-redux";
import { baseURL } from "../../global/global";
import { saveUploadAudioUrl } from "../../actions/createPodreel";


function Createpod(props) {
  const [audioPath, setAudioPath] = useState(null);
  const [exten, setExten] = useState("Extension");
  const [size, setSize] = useState("fileSize");
  
  
  const handleAudioInput = (e) => {
    const byts = e.target.files[0].size;
    const roundOffSize = e.target.files[0].size/1024/1024;
    const totalSize = roundOffSize.toFixed(2);
    const extn = e.target.files[0].name.split('.').pop();

    setAudioPath(e.target.value);
    setSize(totalSize +'Mb');
    setExten(extn);

    const formData = new FormData();
    
    if(byts > 104857600){
      alert("Please select a audio file not more than 100Mb.")
    }else if(byts <= 104857600){
      
      formData.append("audio", e.target.files[0]);
      formData.append("user_id", props.auth.user.user_id);
      
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try{
      if(byts < 104857600){
        axios.post(`${baseURL}api/audio-upload`, formData, config)
        .then((res) => {
          props.dispatch(saveUploadAudioUrl(res.data.data.url))
        })
      }
    }catch(err){
      console.log(err)
    }

  }

    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // };

  //   try {
  //     const res =  axios.post(
  //       `${baseURL}api/audio-upload`,
  //       formData,
  //       config
  //     );

  //     if (res.data.status === true) {
  //       setAudioUrl(res.data.data.url);
  //       setUploadLoader(false);
  //       setSelectedFile({});
  //     }
  //   } catch (err) {
  //     console.log(err.response);
  //     setUploadLoader(false);
  //   }
  // }
  

  

    return (
        <>
           <section className="create-prodcast-sec" style={{height:"100vh"}}>
  <div className="container"> 
   
    <div className="row mt-lg-5">
      <div className="col-lg-12 col-md-12 mx-auto">
        <div className="create-tab">
          <nav>
            <div className="nav nav-tabs d-flex justify-content-between" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"><span>1</span> Upload</a>
              <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"><span>2</span> Profile</a>
              <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false"><span>3</span> Customize</a>
            </div>
          </nav>
          <div className="tab-content mt-lg-5 mt-md-5" id="nav-tabContent">
            <div className="tab-pane my-tab-pane  text-white fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className="upload-body">
                <div className="upload-board">
                  <div className="upload-icon text-center ">
                    <span><i className="fas fa-upload" /></span><br/>
                    <form>
                       <input type = "file" name="file" id="song" accept = ".mp3" onChange={handleAudioInput} />
                    </form>
                    <h6>( {exten} , {size} )</h6>
                    <div className="upload-audio mt-5">
                      <h5>Upload Audio</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            {/* Audio */}

            <div className="tab-pane my-tab-pane  text-white fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div className="upload-body">
                <div className="row" >
                  <div className="col-md-2 p-0">
                    <div className="audio-cliper">
                    <div className="form-group m-0">
                       <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0:00:50" />
                    </div>

                    </div>
                  </div>
                  <div className="col-md-10 p-0" style={{border: '0.3px solid #747474', backgroundColor:'#000'}}>
                    <div className="parent-dvi" style={{position: 'relative', height:'46px'}}>
                      <div className="child-div" style={{position: 'absolute', top: '4%', left: '-1%'}}>
                        <span style={{width: 30, height: 10, backgroundColor: '#fff', display: 'inline-block', clipPath: 'polygon(32% 0, 30% 24%, 28% 49%, 30% 82%, 30% 100%, 41% 0, 48% 100%, 47% 100%, 51% 52%, 48% 0, 48% 1%, 40% 100%)', position: 'absolute', left: '7%', top: '33%'}} />
                        <span style={{width: 70, height: 40, backgroundColor: '#fff', display: 'inline-block', clipPath: 'polygon(32% 0, 30% 24%, 28% 49%, 30% 82%, 30% 100%, 41% 0, 48% 100%, 47% 100%, 51% 52%, 48% 0, 48% 1%, 40% 100%)'}} />
                        <span style={{width: 60, height: 30, backgroundColor: '#fff', display: 'inline-block', clipPath: 'polygon(32% 0, 30% 24%, 28% 49%, 30% 82%, 30% 100%, 41% 0, 48% 100%, 47% 100%, 51% 52%, 48% 0, 48% 1%, 40% 100%)', position: 'absolute', left: '26%', top: '13%'}} />
                        <span />
                      </div> 
                    </div>
                  </div>
                </div>
                <div className="row mt-4" >
                  <div className="col-md-2 pl-0 d-flex align-items-center">
                    <div className="start-time">
                    <div className="form-group m-0">
                    <label htmlFor="exampleInputEmail1">START TIME</label>
                    <input type="email" className="form-control m-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="00:00:00" />
                  </div>

                    </div> 
                  </div>
                  <div className="col-md-2 d-flex align-items-center">
                  <div className="start-time">
                    <div className="form-group m-0">
                    <label htmlFor="exampleInputEmail1">END TIME</label>
                    <input type="email" className="form-control m-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="00:00:00" />
                  </div>
                    </div>  
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                    <div className="play-icon">
                      <span><i className="far fa-play-circle" /></span>
                    </div>
                  </div>
                  <div className="col-md-2 offset-md-3 pr-0">
                  <div className="start-time">
                    <div className="form-group m-0">
                    <label htmlFor="exampleInputEmail1">DURATION TIME</label>
                    <input type="email" readOnly className="form-control form-control-plaintext m-0" id="staticEmail" aria-describedby="emailHelp" placeholder="00:00:00" />
                  </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-between" >
                  <div className="col-md-4 pl-0 mt-4">
                  <div className="card border-dark mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <div className="d-inline">ADD CAPTION</div>
                          <div className="form-check p-0  d-inline form-switch">
                        <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
                      </div>
                        </div>
                        <div className="card-body text-darks">
                        <div className="row">
                        <div className="col-md-6">
                          <h6>Language</h6>
                        </div>
                        <div className="col-md-6">
                          <select className="form-select" aria-label="Default select example">
                            <option selected>Sans, safari</option>
                            <option value={1}>Lato</option>
                            <option value={2}>Arieal</option>
                            <option value={3}>Three</option>
                          </select>
                        </div>
                      </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-md-4 mt-4"> 
                  <div className="card border-dark mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <div className="d-inline">TRANSLATE CAPTION</div>
                          <div className="form-check p-0  d-inline form-switch">
                        <input className="form-check-input float-end" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
                      </div>
                        </div>
                        <div className="card-body text-darks">
                        <div className="row">
                        <div className="col-md-6">
                          <h6>Language</h6>
                        </div>
                        <div className="col-md-6">
                          <select className="form-select" aria-label="Default select example">
                            <option selected>Sans, safari</option>
                            <option value={1}>Lato</option>
                            <option value={2}>Arieal</option>
                            <option value={3}>Three</option>
                          </select>
                        </div>
                      </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="row mt-4">
                   <div className="col-md-12">
                      <div className="render-btn text-end">
                         <NavLink to="" >RENDER</NavLink>
                      </div>
                   </div>
                </div>
              </div>
            </div>



            {/* Customization */}
            <div className="tab-pane my-tab-pane  text-white fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div className="customize-box">
                <div className="row">
                  <div className="col-md-2">
                    <div className="nav flex-column " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">GENERAL</a>
                      <a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">WAVEFROM</a>
                      <a className="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">IMAGE</a>
                      <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">TEXT</a>
                      <a className="nav-link" id="v-pills-progress-tab" data-toggle="pill" href="#v-pills-progress" role="tab" aria-controls="v-pills-progress" aria-selected="false">PROGRESS</a>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div className="tab-pane my-tab-pane  fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="general-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="dimension">
                                    <span className="d-block">Choose an Dimension</span>
                                    <label>Aspect Ratio:</label>
                                    <select>
                                      <option>4:3</option>
                                      <option>3:4</option>
                                      <option>16:9</option>
                                      <option>9:16</option>
                                    </select>
                                  </div>
                                  <div className="colorpickers d-flex align-items-center mt-4">
                                    <div className="background">
                                      <h6>Background</h6>
                                    </div>
                                    <div className="colorpicke">
                                      <div id="cp-component" className="input-group">
                                        <input type="text" defaultValue="#269faf" className="form-control" />
                                        <span className="input-group-addon"><i style={{width: 50, height: 38, marginTop: '-1px'}} /></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>
                          <div className="col-md-6">
                            <div className="preview-tab" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane my-tab-pane  fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="general-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="dimension">
                                    <span className="d-block">Waveform</span>
                                    <div className="row mt-4">
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />  
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />   
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />         
                                      </div>
                                    </div>
                                    <div className="row mt-5">
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />    
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />   
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>
                          <div className="col-md-6">
                            <div className="preview-tab" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane my-tab-pane  fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <div className="images-box row">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="library-upload mt-4 ">
                              
                                  <ul className="nav nav-pills d-flex justify-content-center mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                      <a className="nav-link mr-3 active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">LIBRARY</a>
                                    </li>
                                    <li className="nav-item">
                                      <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">UPLOAD</a>
                                    </li>
                                  </ul>
                                  <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane my-tab-pane  fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="search-bar mt-4 ">
                                <div className="input-group w-75 mx-auto mb-3 ">
                                  <input type="text" className="form-control" placeholder aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                  <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon22"><i className="fas fa-search" /></span>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-3">
                                  <div className="img-box" />
                                </div>
                                <div className="col-md-3">
                                  <div className="img-box" /> 
                                </div>
                                <div className="col-md-3">
                                  <div className="img-box" />
                                </div>
                                <div className="col-md-3">
                                  <div className="img-box" />
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-4">
                                  <div className="img-box" />
                                </div>
                                <div className="col-md-4">
                                  <div className="img-box" /> 
                                </div>
                                <div className="col-md-4">
                                  <div className="img-box" />
                                </div>
                                </div>
                                    </div>
                                    <div className="tab-pane my-tab-pane  fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <div className="uplaod-sec mt-5 text-white d-flex justify-content-center align-items-center flex-column">
                                            <span><i class="fas fa-cloud-upload-alt"></i></span>
                                            <h6 className="text-white">upload File</h6>
                                        </div>
                                    </div>
                                  </div>


                              </div>
                             
                             
                            </div>
                            <div className="col-md-6">
                              <div className="preview-tab" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane my-tab-pane  fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="add-text mt-5">
                              <div className="form-group">
                                <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="ADD TEXT" rows={5} defaultValue={""} />
                              </div>
                              <div className="color">
                                <div className="colorpickers d-flex align-items-center mt-4">
                                  <div className="background">
                                    <h6>Color</h6>
                                  </div>
                                  <div className="colorpicke">
                                    <div id="cp-component2" className="input-group">
                                      <input type="text" defaultValue="#269faf" className="form-control" />
                                      <span className="input-group-addon"><i style={{width: 50, height: 38, marginTop: '-1px'}} /></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="font-style mt-4">
                                <div className="row">
                                  <div className="col-md-2">
                                    <h6>Font</h6>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="form-group">
                                      <select className="form-control" id="exampleFormControlSelect1">
                                        <option>Sans, safari</option>
                                        <option>Lato</option>
                                        <option>arial</option>
                                        <option>Poppins</option>
                                      </select>
                                    </div>
                                  </div>                                                     
                                </div>
                              </div>
                            </div> 
                          </div>
                          <div className="col-md-6">
                            <div className="preview-tab" />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane my-tab-pane  fade" id="v-pills-progress" role="tabpanel" aria-labelledby="v-pills-progress-tab">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="general-content">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="dimension">
                                    <span className="d-block">PROGRESS</span>
                                    <div className="row mt-4">
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />  
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " /> 
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />    
                                      </div>
                                    </div>
                                    <div className="row mt-5">
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />  
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " /> 
                                      </div>
                                      <div className="col-md-4">
                                        <div className="wavefrom-box " />   
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> 
                          </div>
                          <div className="col-md-6">
                            <div className="preview-tab" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></section>
 
        </>
    )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});


export default connect(mapStateToProps)(Createpod);