import React, { useState } from "react";
import youtubeIcon from "../../assets/images/YT-DEFAULT.png";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { fetchSocialAccounts } from "../../actions/createPodreel";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../alert/Alert";
import youtubeDisable from "../../assets/images/YT_DISABLE.png";

const ConnectYoutube = (props) => {

    const auth = useSelector(state => state.auth);
    const youtube = useSelector(state => state.integration.youtube);
    const dispatch = useDispatch();
    const [showModel, setShowModal] = useState(false);
    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user.id
    })

    // Sweetalert
    const [button, setButton] = useState('Disconnect');
    const [showDelete, setShowDelete] = useState(false);

    const deleteIntegration = (id) => {
        setIntData({ ...intData, id: id });
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton('Disconnecting...');
        deleteSocialAccounts();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const responseGoogle = (response) => {
        if (response.code) {
            connectYoutube(response.code);
        } else {
            console.log(response);
        }
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}api/delete-social-network`,
            data: intData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                // dispatch(fetchSocialAccounts(auth.token));
                dispatch(fetchSocialAccounts(auth.user.user_id));
            } else {
                // dispatch(setAlert(res.data.message,'danger'));
                console.log(res.data)
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const connectYoutube = (code) => {
        axios({
            method: "POST",
            url: `${baseURL}api/save-youtube`,
            data: JSON.stringify({ code: code, user_id: auth.user.user_id }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.user.user_id));
            } else {
                // dispatch(setAlert(res.data.message, 'danger'));
            }
        }).catch(err => {

        })
    }

    return (
        <>
            <Alert />
            <div className="connection-box mt-3">
                <div className="row" style={{ backgroundColor: '#060818', padding: '10px 10px' }}>
                    <div className="col-md-1 d-flex justify-content-center">
                        <div className="youtube-logo">
                            {/* <img src={props.data.length>0?youtubeIcon:youtubeDisable} width="56px" alt="youtube"/> */}
                            <img src={youtubeIcon} width="56px" alt="youtube" />
                        </div>
                    </div>
                    <div className="col-md-11">
                        {
                            youtube.length > 0 ?
                                youtube.map((item, index) => {
                                    return (
                                        <div key={index} className="facebook-connection text-white   d-flex justify-content-between  align-items-center">
                                            <div className="facebook-content">
                                                <h6>YouTube</h6>
                                                <p className="m-0">{item?.username} - Added on {item.created}</p>
                                            </div>
                                            <div className="facebook-delete">
                                                <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7 my-custom">Disconnect</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content my-youtube-content">
                                        <h6>YouTube</h6>
                                        
                                        {
                            auth.user.role !=="3" ? <>
                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                <GoogleLogin
                                    clientId="385524880993-kb22h3hvlooun9i0908bsm29bkeafhio.apps.googleusercontent.com"
                                    render={(renderProps) => (
                                        <button onClick={renderProps.onClick} type="button" className="btn-change7 my-custom">Connect</button>
                                    )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    scope="https://www.googleapis.com/auth/youtube"
                                    responseType="code"
                                    accessType="offline"
                                    prompt="consent"
                                    cookiePolicy={"single_host_origin"}
                                />
                            </div>
                            </> : ''
                        }
                                    </div>
                                </div>
                        }

                        {/* <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                    <div className="youtube-content">
                                        <h6>YouTube</h6>
                                        <p className="m-0">Not Connected</p>
                                    </div>
                                </div> */}

<p className="m-0 text-white" style={{width:"fit-content", fontSize:"12px"}}>Not Connected</p>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to disconnect this YouTube account ?
            </SweetAlert>





        </>
    )
}

export default ConnectYoutube;