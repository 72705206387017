import React, { useState } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { reset_password } from "../../actions/auth";
import PropTypes from "prop-types";
import Alert from "../alert/Alert";
import { setAlert } from "../../actions/alert";
import { setLoader, removeLoader } from "../../actions/loader";

function ResetPassword({
  location,
  reset_password,
  isAuthenticated,
  history,
  setAlert,
  loader,
  setLoader,
  removeLoader,
}) {
  const [userPassword, setUserPassword] = useState({
    password: "",
    confirm_password: "",
  });

  const { password, confirm_password } = userPassword;
  const token = queryString.parse(location.search).token;

  const changePassword = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
  };

  const onChangePassword = async (e) => {
    e.preventDefault();
    setLoader();
    if (password !== confirm_password) {
      removeLoader();
      setAlert("Password did not match", "danger");
    } else if (!token) {
      removeLoader();
      setAlert(
        "Password has not been changed go back to email click to the reset password",
        "danger"
      );
    } else {
      const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
      if(pattern.test(password)){
        reset_password(userPassword, token, history);
        removeLoader();
      }else{
        setAlert(
          "Password must contain atleast 1 uppercase, 1 lowercase, 1 number, 1 special character and minimum 6 digit",
          "danger"
        );
        removeLoader();
      }
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="login-page">
      <section id="login-content">
        <div className="container-fluid">
          <div className="row mx-auto align-items-center">
            <div className="col colmn-1 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pr-lg-5">
              <div className="content-wrapper text-center">
                <div className="img-wrapper mb-5">
                  <img
                    className="img-fluid"
                    src="./assets/images/PodReel_logo_v2_040121.png"
                    alt="Logo"
                    width="450px"
                    height="auto"
                  />
                </div>
                <h4 className="text-white font-weight-normal">
                Turn any audio or podcast episode into gorgeous video for your social media within minutes.
                </h4>
              </div>
            </div>
            <div
              className="col colmn-2 col-lg-6 col-md-9 col-sm-10 col-12 mx-auto pl-lg-5"
              style={{ minHeight: "31rem" }} >
              <form id="login-form" onSubmit={(e) => onChangePassword(e)}>
                <div className="form-title text-center">
                  <h2 className="text-white mb-4 mb-md-5">Reset Your Password</h2>
                  {/* <div
                    style={{
                      height: "7rem",
                      width: "100%",
                      marginTop: "10px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Alert authStyle={"authStyle"} />
                  </div> */}
                </div>
                <div className="input-group mb-4">
                  <input
                    id="password"
                    className="form-control"
                    type="password"
                    name="password"
                    autoComplete="off"
                    required=""
                    value={userPassword.password}
                    onChange={(e) => changePassword(e)}
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAWVJREFUOBGVVL2Kg0AQHuXAxDKdcAeprI1NXiCN4HOc4EvEvISQa6xSW+bAJwg26hMEEkQstDU23s7qrnp4FzOw7vx88+3uzKBwOByMpmmOZH3ADBEE4UZgn47jfCNcfCUZE7qDvlBHQYJZJ7fw9jvMEYeBZ7osy6Cq6gj2EsF6vYbdbjdNsFgseECSJCDF4vYwxp2d8oa7aZqg6zqcTicoigJs24Y0TcHzvFHsdzLaLz1hikDY7/cNBvCaVVVRDD7h8XhM4bmPPPFODIvfgCUj4lkyYkgr38l2pDVAx39Cpm4UjuMYfN+nJLMIzuczJVAUBTRNowVmjGI328ye3C+XC+DCOtV1DVEUcRwSWHNIVqsVncIkSUY16qeFc7YK6w5zG4YB2+0WXNeFPM+Ze94c4NU3mw1cr9dRMrL8WUTsc9cqWC6XEAQB3G74K+gFMXwOejfXsDZ3tMqyhDAMIcsyHuxi1g985oWXD2O18QAAAABJRU5ErkJggg==')",
                      backgroundRepeat: "no-repeat",
                      backgroundAttachment: "scroll",
                      backgroundSize: "16px 18px",
                      backgroundPosition: "98% 50%",
                    }}
                  />
                  <label htmlFor="password" className="text-white">
                    Password
                  </label>
                  <span className="underline"> </span>
                </div>
                <div className="input-group mb-4">
                  <input
                    id="confirm-password"
                    className="form-control"
                    type="password"
                    name="confirm_password"
                    autoComplete="off"
                    required=""
                    value={userPassword.confirm_password}
                    onChange={(e) => changePassword(e)}
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAWVJREFUOBGVVL2Kg0AQHuXAxDKdcAeprI1NXiCN4HOc4EvEvISQa6xSW+bAJwg26hMEEkQstDU23s7qrnp4FzOw7vx88+3uzKBwOByMpmmOZH3ADBEE4UZgn47jfCNcfCUZE7qDvlBHQYJZJ7fw9jvMEYeBZ7osy6Cq6gj2EsF6vYbdbjdNsFgseECSJCDF4vYwxp2d8oa7aZqg6zqcTicoigJs24Y0TcHzvFHsdzLaLz1hikDY7/cNBvCaVVVRDD7h8XhM4bmPPPFODIvfgCUj4lkyYkgr38l2pDVAx39Cpm4UjuMYfN+nJLMIzuczJVAUBTRNowVmjGI328ye3C+XC+DCOtV1DVEUcRwSWHNIVqsVncIkSUY16qeFc7YK6w5zG4YB2+0WXNeFPM+Ze94c4NU3mw1cr9dRMrL8WUTsc9cqWC6XEAQB3G74K+gFMXwOejfXsDZ3tMqyhDAMIcsyHuxi1g985oWXD2O18QAAAABJRU5ErkJggg==')",
                      backgroundRepeat: "no-repeat",
                      backgroundAttachment: "scroll",
                      backgroundSize: "16px 18px",
                      backgroundPosition: "98% 50%",
                    }}
                  />
                  <label htmlFor="confirm-password" className="text-white">
                    Confirm Password
                  </label>
                  <span className="underline"> </span>
                </div>
                <div className="btn-wrapper text-center mb-4">
                  {loader ? (
                    <button
                      className="btn btn-1 text-white text-uppercase"
                      type="button"
                      style={{
                        padding: "15px",
                        fontWeight: "700",
                        maxHeight: "50px",
                      }}
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm text-light mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <strong>Loading...</strong>
                    </button>
                  ) : (
                    <button
                      className="btn btn-1 text-white text-uppercase"
                      type="submit"
                    >
                      Reset
                    </button>
                  )}
                </div>
                <div className="form-footer text-center">
                  <Link to="/">
                    <p className="text-white sm-txt">Have Login ?</p>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

ResetPassword.propTypes = {
  reset_password: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  reset_password,
  setAlert,
  removeLoader,
  setLoader,
})(withRouter(ResetPassword));
