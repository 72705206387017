import React, { useState, useEffect } from "react";
import SupportFooter from "./SupportFooter";
import SupportSidenav from "./SupportSidenav";
import { Redirect } from "react-router-dom";

import queryString from "query-string";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import Loader from "../loader/Loader";

const SupportDetail = ({
  support: { loading, support_articles },
  location,
  auth,
}) => {
  const [articleItem, setArticleItem] = useState({});
  const [articles, setArticles] = useState({});
  const [is_client, setIsClient] = useState(false);
  const category = queryString.parse(location.search).category;
  const itemQ = queryString.parse(location.search).item;
  const history = useHistory();

  useEffect(() => {
    if (auth.user) {
      setIsClient(
        auth.loading || !auth.user.is_client ? false : auth.user.is_client
      );
    }
  }, [auth.user, auth.loading]);

  useEffect(() => {
    if (support_articles.length > 0) {
      setArticleItem(
        loading || !support_articles
          ? {}
          : support_articles.find((article) => {
              return article.id === category;
            })
          ? support_articles
              .find((article) => {
                return article.id === category;
              })
              .items.find((item) => {
                return item.id === itemQ;
              })
          : {}
      );
    }
  }, [loading, support_articles, category, itemQ]);

  useEffect(() => {
    if (support_articles.length > 0) {
      setArticles(
        loading || !support_articles
          ? {}
          : support_articles.find((article) => {
              return article.id === category;
            })
      );
    }
  }, [loading, support_articles, category]);

  useEffect(() => {
    if (support_articles.length > 0) {
      const elem = document.getElementById("support_item_wrapper");

      elem.innerHTML = articleItem.content;
      elem.childNodes[0].className = "text-white";
    }
  }, [articleItem]);

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      {support_articles.length < 1 ? (
        <section
          id="art-tpc"
          className="bg-dark tpc-section"
          style={{ display: "block" }}
        >
          <div className="tpc-container container-fluid px-4">
            <div className="row align-items-center" style={{ height: "60vh" }}>
              <div className="col colmn-1 col-12 mb-5 pb-4">
                <div className="title-area">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section
          id="art-tpc"
          className="bg-dark tpc-section"
          style={{ display: "block" }}
        >
          <div className="tpc-container container-fluid px-4">
            <div className="row align-items-start">
              <div className="col colmn-1 col-12 mb-5 pb-4">
                <div className="title-area">
                  <nav className="mb-2" aria-label="Page breadcrumb">
                    <ol
                      className="breadcrumb p-0 mb-0"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <a
                          href="!#"
                          id="get-start"
                          className="tpc-sm-cap"
                          onClick={(e) => {
                            e.preventDefault();
                            return history.push(
                              `/support-article?category=${articles.id}`
                            );
                          }}
                        >
                          Back To {articles.name}
                        </a>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="art-title tpc-lg-cap text-white">
                    VideoReel Tutorial - {articles.name}
                  </h2>
                </div>
              </div>
              <SupportSidenav />
              <div className="col colmn-3 col-lg-8 col-md-7 col-sm-11 col-12 mx-auto">
                <div className="tpc-wrapper w-100">
                  {/* <div className="tpc-meta d-flex mb-4">
                  <p className="tpc-date mr-3 mb-0">
                    <i className="fa fa-calendar-o" aria-hidden="true" />
                    <span className="date ml-2">11 Nov 2020</span>
                  </p>
                  <p className="tpc-type mb-0">
                    <i className="fa fa-folder-o" aria-hidden="true" />
                    <span className="type ml-2">ARTICLE</span>
                  </p>
                  <h6
                    className="tpc-sm-cap flex-grow-1 text-right"
                    style={{ letterSpacing: "1.2px" }}
                  >
                    4 minutes to read
                  </h6>
                </div> */}
                  <div
                    id="support_item_wrapper"
                    className="art-wrapper py-4 mb-4"
                  >
                    {(articleItem.content, "text/html")}
                  </div>
                  {/* <div className="tags-wrapper d-flex flex-wrap">
                  <span className="tag">Bitcoin</span>
                  <span className="tag">Blockchain</span>
                  <span className="tag">ICO</span>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <SupportFooter />
    </>
  );
};

const mapStateToProps = (state) => ({
  support: state.supportData,
  auth: state.auth,
});

SupportDetail.propTypes = {
  support: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SupportDetail);
