import * as React from "react";
import Logo from "./Logo";
import NavSection from "./NavSection";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export function Header({ isAuthenticated }) {
  return !isAuthenticated ? (
    <></>
  ) : (
    // <div>
      <header id="app-header" className="position-fixed w-100">
        <div className="container-fluid px-4">
          
          <nav className="navbar navbar-expand-lg navbar-color p-0">
            <Logo />
            <NavSection />
          </nav>
        </div>
      </header>
    // </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps)(Header);
