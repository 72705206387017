import { fetchSocialAccounts } from "../actions/createPodreel";
import { FETCH_SOCIAL_ACCOUNTS } from "../actions/Types";

const initialState = {
  youtube: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SOCIAL_ACCOUNTS:
      return {
        ...state,
        youtube: payload.youtube,
      };

    default:
      return state;
  }
}
