import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import Preview from "./Preview";
import { connect } from "react-redux";
import {
  saveGeneralData,
  saveImageData,
  saveTextData,
  saveProgressData,
  saveWaveformData,
} from "../../actions/createPodreel";
import { SAVE_GENERAL_DATA } from "../../actions/Types";

const Customize = ({
  saveGeneralData,
  saveImageData,
  saveTextData,
  saveProgressData,
  saveWaveformData,
}) => {
  const [showColorPicker, setShowColorPicker] = useState({
    general: false,
    wavefrom: false,
    textSection: false,
    progress: false,
  });
  const [color, setColor] = useState({
    general: "",
    wavefrom: "",
    textSection: "",
    progress: "",
  });
  const [showTab, setShowTab] = useState("general");
  const [showLibAndUpload, setShowLibAndUpload] = useState("library");
  const [generalAspectRatio, setGeneralAspectRatio] = useState("4.3");
  const [textSectionData, setTextSectionData] = useState({
    text: "",
    fontSize: "1",
    fontFamily: "Arial",
  });
  const wrapperRef = useRef(null);

  // Handle click outside ColorPicker Hide
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowColorPicker({
          general: false,
          wavefrom: false,
          textSection: false,
          progress: false,
        });
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChangeGeneral = (colorChange) => {
    // setBackground(colorChange.hex);
    setColor((value) => ({ ...value, general: colorChange.hex }));
  };

  const handleChangeWaveform = (colorChange) => {
    // setBackground(colorChange.hex);
    setColor((value) => ({ ...value, wavefrom: colorChange.hex }));
  };

  const handleChangeTextSection = (colorChange) => {
    // setBackground(colorChange.hex);
    setColor((value) => ({ ...value, textSection: colorChange.hex }));
  };

  const handleChangeProgress = (colorChange) => {
    // setBackground(colorChange.hex);
    setColor((value) => ({ ...value, progress: colorChange.hex }));
  };

  const popover = {
    position: "fixed",
    zIndex: "1000",
    left: "270px",
    top: "240px",
  };
  const cover = {
    position: "relative",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const swatch = {
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    cursor: "pointer",
    width: "50px",
    height: "30px",
    borderRadius: "5px",
  };

  const [Dimension, setDimension] = useState({
    width: "0000",
    height: "0000",
  });

  const forSettingDimension = (e) => {
    const target = e.target;
    if (e.target.value > 1920 || e.target.value.length > 4) return;
    setDimension((val) => ({ ...val, [target.name]: target.value }));
  };

  return (
    <div>
      <div className='min-h50'>
        <h1 className='text-white'>
          <>
            <div className='row'>
              <div className='col colmn-1 col-1 px-0 bg-dark'>
                <div
                  id='editor-nav'
                  className='nav flex-column nav-pills text-center nav-sidebar-bg'
                  role='tablist'
                  aria-orientation='vertical'
                >
                  <a
                    id='save-tab'
                    className='nav-link v-tab-link'
                    role='tab'
                    aria-controls='save-tab-cont'
                    aria-selected='false'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTab("general");
                    }}
                  >
                    <span className='icon d-block mb-2'>
                      <svg width='24px' height='20px' viewBox='0 0 41 34'>
                        <use xlinkHref='./assets/images/ico-video.svg#ico-video' />
                      </svg>
                    </span>
                    <span style={{ fontSize: 14 }}>GENERAL</span>
                  </a>
                  <a
                    id='save-tab'
                    className='nav-link v-tab-link '
                    role='tab'
                    aria-controls='save-tab-cont'
                    aria-selected='false'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTab("waveform");
                    }}
                  >
                    <span className='icon d-block mb-2'>
                      <svg width='24px' height='20px' viewBox='0 0 41 34'>
                        <use xlinkHref='./assets/images/ico-video.svg#ico-video' />
                      </svg>
                    </span>
                    <span style={{ fontSize: 14 }}>WAVEFORM</span>
                  </a>

                  <a
                    id='save-tab'
                    className='nav-link v-tab-link '
                    role='tab'
                    aria-controls='save-tab-cont'
                    aria-selected='false'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTab("image");
                    }}
                  >
                    <span className='icon d-block mb-2'>
                      <svg width='24px' height='20px' viewBox='0 0 41 34'>
                        <use xlinkHref='./assets/images/ico-video.svg#ico-video' />
                      </svg>
                    </span>
                    <span style={{ fontSize: 14 }}>IMAGE</span>
                  </a>
                  <a
                    id='save-tab'
                    className='nav-link v-tab-link '
                    role='tab'
                    aria-controls='save-tab-cont'
                    aria-selected='false'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTab("text");
                    }}
                  >
                    <span className='icon d-block mb-2'>
                      <svg width='24px' height='20px' viewBox='0 0 41 34'>
                        <use xlinkHref='./assets/images/ico-video.svg#ico-video' />
                      </svg>
                    </span>
                    <span style={{ fontSize: 14 }}>TEXT</span>
                  </a>
                  <a
                    id='save-tab'
                    className='nav-link v-tab-link '
                    role='tab'
                    aria-controls='save-tab-cont'
                    aria-selected='false'
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTab("progress");
                    }}
                  >
                    <span className='icon d-block mb-2'>
                      <svg width='24px' height='20px' viewBox='0 0 41 34'>
                        <use xlinkHref='./assets/images/ico-video.svg#ico-video' />
                      </svg>
                    </span>
                    <span style={{ fontSize: 14 }}>PROGRESS</span>
                  </a>
                </div>
              </div>
              <div></div>
              {showTab === "general" ? (
                <div
                  className='col-11 px-0 flex-grow-1'
                  style={{ backgroundColor: "#000", height: "400px" }}
                >
                  <div>
                    <div class=' text-white text-center mb-5'>
                      <small>General</small>
                    </div>
                    <div className='d-flex'>
                      <div className='col-6 bg-card centerd space-around'>
                        <div>
                          <span className='text-white d-block'>
                            {" "}
                            Choose an Dimension{" "}
                          </span>
                          <div>
                            <label htmlFor='fontfamily' className='pr-5'>
                              {" "}
                              Aspect Ratio:
                            </label>
                            <select
                              className='font-in'
                              name='fontsize'
                              id='fontsize'
                              form='fontsize'
                              onChange={(e) =>
                                setGeneralAspectRatio(e.target.value)
                              }
                              value={generalAspectRatio}
                            >
                              <option value='4:3'> 4:3 </option>
                              <option value='3:4'> 3:4 </option>
                              <option value='16:9'> 16:9 </option>
                              <option value='9:16'> 9:16 </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <span className='text-white d-block'>
                            {" "}
                            Choose a Color{" "}
                          </span>
                          {showColorPicker.general ? (
                            <div style={popover} ref={wrapperRef}>
                              <div
                                style={cover}
                                onClick={() =>
                                  setShowColorPicker((value) => ({
                                    ...value,
                                    general: false,
                                  }))
                                }
                              />
                              <SketchPicker
                                color={color.general}
                                onChange={handleChangeGeneral}
                              />
                            </div>
                          ) : null}
                          <button
                            class='btn btn-primary'
                            onClick={() =>
                              setShowColorPicker((value) => ({
                                ...value,
                                general: true,
                              }))
                            }
                            style={{
                              width: "100px",
                              height: "30px",
                              background: color.general,
                            }}
                          >
                            {" "}
                          </button>

                          <button
                            class='btn btn-primary'
                            style={{
                              width: "100px",
                              height: "30px",
                            }}
                            onClick={() =>
                              saveGeneralData([
                                {
                                  dimension: generalAspectRatio,
                                  width: "1920",
                                  height: "1080",
                                  backgroundColor: color.general,
                                },
                              ])
                            }
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                      <Preview />
                    </div>
                  </div>
                </div>
              ) : null}
              {showTab === "waveform" ? (
                <div
                  className='col-11 px-0 flex-grow-1'
                  style={{ backgroundColor: "#000", height: "400px" }}
                >
                  <div class=' text-white text-center mb-5'>
                    <small>Waveform</small>
                  </div>
                  <div className='d-flex'>
                    <div className='col-6 bg-card centerd space-around'>
                      <div>
                        <span className='text-white d-block pt-2'>
                          {" "}
                          Waveform{" "}
                        </span>

                        <div class='waveform-dimension bg-primary  text-white pl-2 mt-2 mb-2'></div>
                        <div class='waveform-dimension bg-white  text-white pl-1 mt-2 mb-2'></div>

                        <div class='waveform-dimension bg-primary  text-white pl-2 mt-2 mb-2'></div>

                        <div class='waveform-dimension bg-white  text-white pl-1 mt-2 mb-2'></div>
                      </div>

                      <div>
                        <span className='text-white d-block'>
                          {" "}
                          Choose a Color{" "}
                        </span>
                        {showColorPicker.wavefrom ? (
                          <div style={popover} ref={wrapperRef}>
                            <div
                              style={cover}
                              onClick={() =>
                                setShowColorPicker((value) => ({
                                  ...value,
                                  wavefrom: false,
                                }))
                              }
                            />
                            <SketchPicker
                              color={color.wavefrom}
                              onChange={handleChangeWaveform}
                            />
                          </div>
                        ) : null}
                        <button
                          class='btn btn-primary'
                          onClick={() =>
                            setShowColorPicker((value) => ({
                              ...value,
                              wavefrom: true,
                            }))
                          }
                          style={{
                            width: "100px",
                            height: "30px",
                            background: color.wavefrom,
                          }}
                        >
                          {" "}
                        </button>

                        <button
                          class='btn btn-primary'
                          style={{
                            width: "100px",
                            height: "30px",
                          }}
                          onClick={() =>
                            saveWaveformData([
                              {
                                posx: "200",
                                posy: "200",
                                height: "200",
                                width: "1920",
                                color: color.wavefrom,
                                mode: "line",
                              },
                            ])
                          }
                        >
                          Proceed
                        </button>
                      </div>
                    </div>

                    <Preview />
                  </div>
                </div>
              ) : null}
              {showTab === "image" ? (
                <div
                  className='col-11 px-0 flex-grow-1'
                  style={{ backgroundColor: "#000", height: "400px" }}
                >
                  <div class=' text-white text-center mb-5'>
                    <small>Image</small>
                  </div>
                  <div className='d-flex'>
                    <div className='col-6 bg-card space-around'>
                      <div className='d-flex'>
                        <button
                          className='btn btn-primary'
                          style={{ width: "50%" }}
                          onClick={() => setShowLibAndUpload("library")}
                        >
                          Library
                        </button>
                        <button
                          className='btn btn-success'
                          style={{ width: "50%" }}
                          onClick={() => setShowLibAndUpload("upload")}
                        >
                          Custom Upload
                        </button>
                      </div>
                      <div>
                        <div>
                          {/* start of Image Search */}
                          {showLibAndUpload === "library" ? (
                            <>
                              <div className='row centerd'>
                                <div className='col-7 p-0'>
                                  <div className='form-group m-0'>
                                    <label className='input-label'>
                                      Search for media{" "}
                                    </label>
                                    <input
                                      className='form-control input-dark-accnt personal-info'
                                      type='text'
                                      name='search'
                                      placeholder='Enter a keyword'
                                      autoComplete='off'
                                      required
                                      defaultValue='rose'
                                    />
                                  </div>
                                </div>
                                <div className='col-1 p-0'>
                                  <button
                                    className='btn btn-default border border-dark'
                                    type='submit'
                                    style={{
                                      borderRadius: "0px 8px 8px 0px",
                                      height: "58px",
                                      padding: "0px",
                                      background: "rgb(28, 183, 242)",
                                      width: "100%",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <i
                                      className='fa fa-search'
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                </div>
                              </div>

                              <div className='row general_row img-res'>
                                <div
                                  className='col-3 my-2'
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src='https://mediaapidata.s3.us-west-2.amazonaws.com/images/Art/art_56.jpg'
                                    alt=''
                                    className='img-thumbnail search_img'
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: "none",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Pixabay
                                  </span>
                                </div>
                                <div
                                  className='col-3 my-2'
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src='https://mediaapidata.s3.us-west-2.amazonaws.com/images/Beautiful%20Blur/beautiful_blur_180.jpg'
                                    alt=''
                                    className='img-thumbnail search_img'
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: "none",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Pixabay
                                  </span>
                                </div>
                                <div
                                  className='col-3 my-2'
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src='https://mediaapidata.s3.us-west-2.amazonaws.com/images/All%20the%20Colour/all_the_colour_051.jpg'
                                    alt=''
                                    className='img-thumbnail search_img'
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: "none",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Pixabay
                                  </span>
                                </div>

                                <div
                                  className='col-3 my-2'
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src='https://mediaapidata.s3.us-west-2.amazonaws.com/images/All%20the%20Colour/all_the_colour_051.jpg'
                                    alt=''
                                    className='img-thumbnail search_img'
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "150px",
                                      border: "none",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      top: "15px",
                                      left: "25px",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                      color: "black",
                                      padding: "3px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      fontFamily: "SourceSansPro",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Pixabay
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {/* End of Image Search */}
                        </div>
                        {/* start of upload */}
                        {showLibAndUpload === "upload" ? (
                          <div>
                            <div className='row general_row d-flex justify-content-center search-row'>
                              <div className='col-9 search-area '>
                                <div
                                  className='row d-flex justify-content-around align-items-center py-2'
                                  style={{ height: "100%" }}
                                >
                                  <div
                                    className='col-11 p-0'
                                    style={{
                                      height: "75%",
                                      width: "100%",
                                      backgroundColor: "rgb(54, 56, 57)",
                                      border: "2px dashed white",
                                    }}
                                  >
                                    <form
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div className='upload-btn-wrapper'>
                                        <div>
                                          <button
                                            style={{
                                              backgroundColor:
                                                "rgb(54, 56, 57)",
                                              color: "white",
                                              border: "none",
                                              fontSize: "1rem",
                                            }}
                                          >
                                            Upload File (JPG, PNG and JPEG upto
                                            5MB &amp; 500 x 500 pixels
                                            recommended)
                                          </button>
                                          <i
                                            className='fa fa-cloud-upload text-center'
                                            aria-hidden='true'
                                            style={{
                                              display: "block",
                                              color: "white",
                                              fontSize: "1.9rem",
                                              paddingTop: "10px",
                                            }}
                                          />
                                        </div>
                                        <input
                                          type='file'
                                          className='custom-file-input'
                                          id='customFile'
                                          name='file'
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            padding: "0px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>












                            

                            <div className='row general_row centered img-res centerd'>
                              <div
                                className='col-3 my-2'
                                style={{ position: "relative" }}
                              >
                                <img
                                  src='https://mediaapidata.s3.us-west-2.amazonaws.com/images/Art/art_56.jpg'
                                  alt=''
                                  className='img-thumbnail search_img'
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "150px",
                                    border: "none",
                                  }}
                                />
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "15px",
                                    left: "25px",
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                    color: "black",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "5px",
                                    fontFamily: "SourceSansPro",
                                    fontSize: "12px",
                                  }}
                                >
                                  Pixabay
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {/* End of upload */}
                      </div>
                    </div>
                    <Preview />
                  </div>
                </div>
              ) : null}
              {showTab === "text" ? (
                <div
                  className='col-11 px-0 flex-grow-1'
                  style={{ backgroundColor: "#000", height: "400px" }}
                >
                  <div class=' text-white text-center mb-5'>
                    <small>Text</small>
                  </div>
                  <div className='d-flex'>
                    <div className='col-6 bg-card centerd space-around'>
                      <div>
                        <div>
                          <span class='text-white pl-1 d-flex'>
                            <label htmlFor='text-area' className='pr-5'>
                              {" "}
                              text:
                            </label>
                            <textarea
                              id='text-area'
                              name='text-area'
                              rows='8'
                              cols='50'
                              value={textSectionData.text}
                              onChange={(e) => {
                                let target = e.target;
                                setTextSectionData((value) => ({
                                  ...value,
                                  text: target.value,
                                }));
                              }}
                            >
                              Lorem Ipsum Dollar Sit Amet
                            </textarea>
                          </span>
                        </div>
                        <div>
                          <div>
                            <label htmlFor='fontsize' className='pr-4'>
                              {" "}
                              font size:
                            </label>
                            <select
                              className='font-in'
                              name='fontsize'
                              id='fontsize'
                              form='fontsize'
                              value={textSectionData.fontSize}
                              onChange={(e) => {
                                let target = e.target;
                                setTextSectionData((value) => ({
                                  ...value,
                                  fontSize: target.value,
                                }));
                              }}
                            >
                              <option value='1'>1 </option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='8'>8</option>
                            </select>
                          </div>
                          <div>
                            <label htmlFor='fontfamily' className='pr-2'>
                              {" "}
                              font family:
                            </label>
                            <select
                              className='font-in'
                              name='fontsize'
                              id='fontsize'
                              form='fontsize'
                              value={textSectionData.fontFamily}
                              onChange={(e) => {
                                let target = e.target;
                                setTextSectionData((value) => ({
                                  ...value,
                                  fontFamily: target.value,
                                }));
                              }}
                            >
                              <option value='Arial'>Arial </option>
                              <option value='Sanspro'> Sanspro </option>
                              <option value='Poppins'> Poppins </option>
                              <option value='Times New Roman'>
                                {" "}
                                Times New Roman
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className='text-white d-block'>
                          {" "}
                          Choose a Color{" "}
                        </span>
                        {showColorPicker.textSection ? (
                          <div style={popover} ref={wrapperRef}>
                            <div
                              style={cover}
                              onClick={() =>
                                setShowColorPicker((value) => ({
                                  ...value,
                                  textSection: false,
                                }))
                              }
                            />
                            <SketchPicker
                              color={color}
                              onChange={handleChangeTextSection}
                            />
                          </div>
                        ) : null}
                        <button
                          class='btn btn-primary'
                          onClick={() =>
                            setShowColorPicker((value) => ({
                              ...value,
                              textSection: true,
                            }))
                          }
                          style={{
                            width: "100px",
                            height: "30px",
                            background: color.textSection,
                          }}
                        >
                          {" "}
                        </button>

                        <button
                          class='btn btn-primary'
                          style={{
                            width: "100px",
                            height: "30px",
                          }}
                          onClick={() =>
                            saveTextData([
                              {
                                posx: "250",
                                posy: "300",
                                fontfamily: textSectionData.fontFamily,
                                fontsize: textSectionData.fontSize,
                                text: textSectionData.text,
                                color: color.textSection,
                              },
                            ])
                          }
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                    <Preview />
                  </div>
                </div>
              ) : null}
              {showTab === "progress" ? (
                <div
                  className='col-11 px-0 flex-grow-1'
                  style={{ backgroundColor: "#000", height: "400px" }}
                >
                  <div class=' text-white text-center mb-5'>
                    <small>Progress</small>
                  </div>
                  <div className='d-flex'>
                    <div className='col-6 bg-card centerd space-around'>
                      <div>
                        <span className='text-white d-block pt-2'>
                          {" "}
                          Progress{" "}
                        </span>
                        <div class='waveform-dimension bg-primary  text-white pl-2 mt-2 mb-2'></div>
                        <div class='waveform-dimension bg-white  text-white pl-1 mt-2 mb-2'></div>
                        <div class='waveform-dimension bg-primary  text-white pl-2 mt-2 mb-2'></div>
                        <div class='waveform-dimension bg-white  text-white pl-1 mt-2 mb-2'></div>
                      </div>
                      <div>
                        <span className='text-white d-block'>
                          {" "}
                          Choose a Color{" "}
                        </span>
                        {showColorPicker.progress ? (
                          <div style={popover} ref={wrapperRef}>
                            <div
                              style={cover}
                              onClick={() =>
                                setShowColorPicker((value) => ({
                                  ...value,
                                  progress: false,
                                }))
                              }
                            />
                            <SketchPicker
                              color={color.progress}
                              onChange={handleChangeProgress}
                            />
                          </div>
                        ) : null}
                        <button
                          class='btn btn-primary'
                          onClick={() =>
                            setShowColorPicker((value) => ({
                              ...value,
                              progress: true,
                            }))
                          }
                          style={{
                            width: "100px",
                            height: "30px",
                            background: color.progress,
                          }}
                        >
                          {" "}
                        </button>
                        <button
                          class='btn btn-primary'
                          style={{
                            width: "100px",
                            height: "30px",
                          }}
                          onClick={() =>
                            saveProgressData([
                              {
                                color: color.progress,
                              },
                            ])
                          }
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                    <Preview />
                  </div>
                </div>
              ) : null}
            </div>
          </>
        </h1>
      </div>
    </div>
  );
};
export default connect(null, {
  saveGeneralData,
  saveImageData,
  saveTextData,
  saveProgressData,
  saveWaveformData,
})(Customize);
