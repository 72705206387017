import React, { useEffect, useState } from "react";
import { saveProgressData } from "../../../../actions/createPodreel";
import { connect } from "react-redux";
import randomstring from "randomstring";

const Progress = (props) => {
  const [progress, setProgress] = useState({
    id: "",
    show: false,
    color: "",
    width: "",
    height: "",
    top: "",
    left: "",
  });

  useEffect(() => {
    setProgress({
      ...props.customize.progress,
    });
  }, [props.customize]);

  const handleProgress = (e) => {
    if (e.target.checked === true) {
      const proLess = {
        ...progress,
        show: true,
        id: randomstring.generate(7),
        color: "#0000FF",
      };
      props.saveProgressData(proLess);
    }else if(e.target.checked === false){
      const proLess = {
        ...progress,
        show: false,
        id:"",
        color: "",
      };
      props.saveProgressData(proLess);
    }
  };

  return (
    <div
      className="tab-pane fade"
      id="v-pills-progress"
      role="tabpanel"
      aria-labelledby="v-pills-progress-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="general-content">
            <div className="row">
              <div className="col-md-12">
                <div className="dimension">
                  <span className="d-block">
                    Enable / Disable Progress Bar for your Podcast
                  </span>

                  <div className="row mt-3 mb-2 ps-3">
                    <div
                      className="col-md-4 d-flex form-check p-0  d-inline form-switch"
                      stye
                    >
                      <input
                        className="form-check-input float-end m-0"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        style={{ marginRight: "47px" }}
                        onChange={handleProgress}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customize: state.createPodreel.customize,
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveProgressData: (data) => dispatch(saveProgressData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Progress);
