import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import styled from "styled-components";

const WhiteLabelNav = ({ user, loading, location, whiteLabel, activeTab }) => {
  const [userId, setUserId] = useState("");
  const [is_client, setIsClient] = useState(false);
  const [accountType, setAccountType] = useState([]);
  const active_data = queryString.parse(location);


  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  useEffect(() => {
    setUserId(loading || !user ? "" : user.user_id);
  }, [user, loading]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  return (
    <div className="col-11 " style={{ marginTop: "5rem" }}>
      <div className="row align-items-center justify-content-center">
        <WhitelabelStyle>
          <div className="col-12 p-0">
            <ul id="breadcrumb">
              <li>
                {is_client === true ? null : accountType.includes("business") ||
                  accountType.includes("bundle") ? (
                  <Link
                    to="./whitelabel"
                    className={
                      window.location.pathname === "/whitelabel"
                        ? "selective"
                        : "nothingchange"
                    }
                  >
                    General <span class="icon icon-double-angle-right"></span>
                  </Link>
                ) : null}
              </li>
              <li>
                {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("unlimited") ? (
                  <Link
                    className={
                      window.location.pathname === "/temp"
                        ? "selective"
                        : "nothingchange"
                    }
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object
                        ? "./temp"
                        : `./${activeTab}`
                    }
                  >
                    Template <span class="icon icon-double-angle-right"></span>
                  </Link>
                ) : null}
              </li>
              <li>
                {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={
                      window.location.pathname === "/funnel"
                        ? "selective"
                        : "nothingchange"
                    }
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object &&
                      whiteLabel.template_id !== ""
                        ? "./funnel"
                        : `./${activeTab}`
                    }
                  >
                    Funnel <span class="icon icon-double-angle-right"></span>
                  </Link>
                ) : null}
              </li>
              <li>
                {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("dfy") ? (
                  <Link
                    className={
                      window.location.pathname === "/payment"
                        ? "selective"
                        : "nothingchange"
                    }
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object &&
                      whiteLabel.template_id !== "" &&
                      whiteLabel.price.length > 0
                        ? "./payment"
                        : `./${activeTab}`
                    }
                  >
                    Payment <span class="icon icon-double-angle-right"></span>
                  </Link>
                ) : null}
              </li>
              <li>
                {is_client === true ? null : accountType.includes("business") ||
                  accountType.includes("bundle") ? (
                  <Link
                    className={
                      window.location.pathname === "/smtp"
                        ? "selective"
                        : "nothingchange"
                    }
                    style={{ marginRight: "0px" }}
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object &&
                      whiteLabel.template_id !== "" &&
                      whiteLabel.price.length > 0 &&
                      whiteLabel.payment.length > 0
                        ? "./smtp"
                        : `./${activeTab}`
                    }
                  >
                    SMTP <span class="icon icon-double-angle-right"></span>
                  </Link>
                ) : null}
              </li>
            </ul>

            {/* <nav aria-label="breadcrumb" style={{ background: "black" }}>
            <ol class="breadcrumb " style={{ background: "black" }}>
              <li
                className="breadcrumb-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="General"
              >
                {is_client === true ? null : accountType.includes("business") ||
                  accountType.includes("bundle") ? (
                  <Link to="./whitelabel">General</Link>
                ) : null}
              </li>
              <li
                className="breadcrumb-item"
                style={{
                  width: "7px",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {"/"}
              </li>
              <li
                className="breadcrumb-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Template"
              >
                {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("unlimited") ? (
                  <Link
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object
                        ? "./temp"
                        : `./${activeTab}`
                    }
                  >
                    Template
                  </Link>
                ) : null}
              </li>

              <li
                className="breadcrumb-item"
                style={{
                  width: "7px",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {"/"}
              </li>
              <li
                className="breadcrumb-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Udemy Style website"
              >
                {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("dfy") ? (
                  <Link
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object &&
                      whiteLabel.template_id !== ""
                        ? "./funnel"
                        : `./${activeTab}`
                    }
                  >
                    Funnel
                  </Link>
                ) : null}
              </li>

              <li
                className="breadcrumb-item"
                style={{
                  width: "7px",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {"/"}
              </li>

              <li
                className="breadcrumb-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="DFY Course"
              >
                {is_client === true ? null : accountType.includes("bundle") ||
                  accountType.includes("dfy") ? (
                  <Link
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object &&
                      whiteLabel.template_id !== "" &&
                      whiteLabel.price.length > 0
                        ? "./payment"
                        : `./${activeTab}`
                    }
                  >
                    Payment
                  </Link>
                ) : null}
              </li>

              <li
                className="breadcrumb-item"
                style={{
                  width: "7px",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {"/"}
              </li>

              <li
                className="breadcrumb-item"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Video Commercial"
              >
                {is_client === true ? null : accountType.includes("business") ||
                  accountType.includes("bundle") ? (
                  <Link
                    to={
                      Object.keys(whiteLabel.general).length > 0 &&
                      whiteLabel.general.constructor === Object &&
                      whiteLabel.template_id !== "" &&
                      whiteLabel.price.length > 0 &&
                      whiteLabel.payment.length > 0
                        ? "./smtp"
                        : `./${activeTab}`
                    }
                  >
                    SMTP
                  </Link>
                ) : null}
              </li>
            </ol>
          </nav> */}
          </div>
        </WhitelabelStyle>
      </div>
    </div>
  );
};

const WhitelabelStyle = styled.div`
  a,
  span {
    transition: none !important;
  }
`;

WhiteLabelNav.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps)(WhiteLabelNav);
