import axios from 'axios';

const setAuthToken = token => {
  if(token){
    axios.defaults.headers.common['X-Auth'] = "Bearer " + token;
  }
  else{
    delete axios.defaults.headers.common['X-Auth'];
  }
}

export default setAuthToken;
