import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


function Logo() {

  return (
    <>
      <Link className="navbar-brand p-0 pl-5" to="/dashboard">
        <img
          className="img-fluid videoreel-favicon-icon"
          src={ "./assets/images/logo.png"}
          // src={`${logo !== "" ? logo : "./assets/images/video-reel.png"}`}
          alt="Logo"
        />

        {/* <img className="img-fluid videoreel-favicon-icon" src={require("src/assets/images/video-reel.svg")} alt="App Logo" /> */}
      </Link>
    </>
  );
}


export default Logo;
