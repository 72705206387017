import React from "react";
import cCss from "./Cline.module.css";

function Cline({ movableTarget }) {
  return (
    <div
      className={cCss.mainLine}
      onClick={movableTarget}
      style={{
        position: "absolute",
        top: "7rem",
        width: "100%",
        right: "5.7rem",
      }}
    >
      <div className="wide">
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>
      <div className="wide2" style={{ marginLeft: "162px" }}>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>

      <div className="wide3" style={{ marginLeft: "82px" }}>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>

      <div className="wide4" style={{ marginLeft: "243px" }}>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>

      <div className="wide5" style={{ marginLeft: "324px" }}>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>

      <div className="wide6" style={{ marginLeft: "405px" }}>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>

      <div className="wide7" style={{ marginLeft: "478px" }}>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDiv}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
        <div className={cCss.childDivOne}>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
          <span> </span>
        </div>
      </div>
    </div>
  );
}

export default Cline;
