import React, { useState, useEffect } from "react";
import { baseURL } from "../../global/global";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import { logout } from "../../actions/auth";

function Modal({ close, values, auth: { user, loading }, setAlert, logout }) {
  const [buttonText, setButtonText] = useState(true);
  const [user_id, setUserId] = useState("");

  const { img, headers, msg, firstButton, secondButton, type, text } = values;

  // fetching useId
  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  const handleConfirm = async () => {
    setButtonText(false);
    const data = {
      id: user_id,
      type: type,
      [type]: "yes",
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.post(`${baseURL}api/gdpr-request`, data, config);
      if (res.data.status === true) {
        if (type === "downloadUserData") {
          window.location.href = res.data.data.url;
          setAlert(res.data.message, "success");
        } else if (type === "stopProcessingData") {
          logout(user_id);
          setAlert(res.data.message, "success");
        } else if (type === "deleteUserData") {
          logout(user_id);
          setAlert(res.data.message, "success");
        }
      } else {
        setAlert(res.data.message, "danger");
      }

      close();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className={`modal fade show ${values ? "d-block" : ""}`}
      style={{
        background: "rgba(0,0,0,0.9)",
      }}
      tabIndex={-1}
      role="dialog"
      data-backdrop="static"
      aria-labelledby="delete-warn"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mx-auto"
        role="document"
      >
        <div className="modal-content">
          <button
            type="button"
            className="close mb-3 text-right"
            data-dismiss="modal"
            aria-label="Close"
            style={{ opacity: 1 }}
            onClick={close}
          >
            <img
              className="img-fluid"
              src="./assets/images/close.svg"
              alt="Close"
            />
          </button>
          <div className="modal-body bg-white text-center">
            <div className="img-wrapper mb-3 pb-1 pt-5">
              <img className="img-fluid" src={img} alt="Warning" />
            </div>
            <h4
              className="modal-title mb-2"
              id="delete-warn"
              style={{ color: "#000000" }}
            >
              {headers}
            </h4>
            <p className="w-100 mx-auto mb-3 pb-3 text-center mr-auto gdpr-warning-text px-2">
              {msg}
            </p>
            <div className="modal-footer border-top-0 p-0 pb-5 justify-content-center">
              <button
                type="button"
                className="btn btn-2 fs-12"
                data-dismiss="modal"
                onClick={close}
              >
                {firstButton}
              </button>
              <button
                id="confirm"
                type="button"
                className="btn btn-3 text-white fs-12"
                onClick={handleConfirm}
              >
                {buttonText ? secondButton : text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  auth: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, logout })(Modal);
