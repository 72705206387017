import React from 'react'

const Preview = () => {
    return (
      <div className="col-5 bg-card centerd-top" >
        <div className="bg-white preview-tab-dim" >
           <small className="text-white">Preview</small>
        </div>
      </div>
    )
}

export default Preview
