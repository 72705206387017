import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateEmail } from "../../actions/auth";
import { setLoader } from "../../actions/loader";
import PropTypes from "prop-types";

const UpdateEmail = ({ user, loading, updateEmail, setLoader, loader }) => {
  const [userEmail, setUserEmail] = useState({ email: "", id: "" });
  const [newEmail, setNewEmail] = useState("");
  const [load, setLoad] = useState(false);

  useEffect(
    () =>
      setUserEmail({
        email: loading || !user.email ? "" : user.email,
        id: loading || !user.user_id ? "" : user.user_id,
      }),
    [loading, user]
  );
  const submitPersonalInformation = async (e) => {
    e.preventDefault();
    setLoad(true);
    const { email, id } = userEmail;
    const userInformation = {
      id,
      email:newEmail,
    };
    await updateEmail(userInformation, setLoad);
  };
  return (
    <>
      <div className="container px-4  mb-5">
        <h4 className="text-white personal-info mt-5 mb-3 text-left">
          Change Email
        </h4>
        <div className="profile-info-box pad-sm">
          <form onSubmit={(e) => submitPersonalInformation(e)}>
            <div className="row d-flex justify-content-center ">
              <div className="col col-lg-6 col-12 pr-xl-0">
                <div className="form-group form-group-mr-profile">
                  <label className="input-label"> Current Email Address </label>
                  <input
                    className="form-control input-dark personal-info"
                    type="email"
                    name="email"
                    placeholder="............"
                    value={userEmail.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="col col-lg-6 col-12">
                <div className="form-group form-group-mr-profile">
                  <label className="input-label"> New Email Address </label>
                  <input
                    className="form-control input-dark"
                    type="email"
                    name="newEmail"
                    placeholder="............"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col col-md-12 text-center mt-5">
              {load ? (
                <button
                  className="btn btn-3 update-pass"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm text-light mx-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <strong>Loading...</strong>
                </button>
              ) : (
                <button className="btn btn-3 update-pass">Update</button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

UpdateEmail.propTypes = {
  user: PropTypes.object,
  updateEmail: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, { updateEmail, setLoader })(
  UpdateEmail
);
