import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import WhiteLabelNav from "./WhiteLabelNav";
import { setPriceData } from "../../actions/whiteLabel";
import { useHistory, Redirect } from "react-router-dom";

const Funnel = ({ user, loading, whiteLabel, setPriceData }) => {
  const [accountType, setAccountType] = useState([]);
  const [loader, setLoader] = useState(false);
  const [funnelMember, setFunnelMember] = useState([]);
  const [bronzePrice, setBronzePrice] = useState({
    name: "",
    price: "",
    type: "bronze",
    id: "",
  });
  const [silverPrice, setSilverPrice] = useState({
    name: "",
    price: "",
    type: "silver",
    id: "",
  });
  const [goldPrice, setGoldPrice] = useState({
    name: "",
    price: "",
    type: "gold",
    id: "",
  });
  const [multipulePrice, setMultipulePrice] = useState({
    bronze: false,
    silver: false,
    gold: false,
  });
  const history = useHistory();

  useEffect(() => {
    if (whiteLabel.memberShip.length > 0) {
      setFunnelMember(whiteLabel.memberShip);
      whiteLabel.memberShip.map((value) => {
        if (value.name === "Bronze") {
          setBronzePrice((val) => ({ ...val, id: value.id }));
        } else if (value.name === "Sliver") {
          setSilverPrice((val) => ({ ...val, id: value.id }));
        } else if (value.name === "Gold") {
          setGoldPrice((val) => ({ ...val, id: value.id }));
        }
      });
    }
  }, [whiteLabel.memberShip]);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (whiteLabel.price.length > 0) {
      whiteLabel.price.map((value) => {
        if (value.type === "bronze") {
          setBronzePrice({ ...value });
          setMultipulePrice((value) => ({ ...value, bronze: true }));
        } else if (value.type === "silver") {
          setSilverPrice({ ...value });
          setMultipulePrice((value) => ({ ...value, silver: true }));
        } else if (value.type === "gold") {
          setGoldPrice({ ...value });
          setMultipulePrice((value) => ({ ...value, gold: true }));
        }
      });
    } else if (whiteLabel.whiteLabelData.length > 0) {
      whiteLabel.whiteLabelData[0].price.map((value) => {
        if (value.name === "Bronze") {
          setBronzePrice((data) => ({ ...data, ...value }));
          setMultipulePrice((value) => ({ ...value, bronze: true }));
        } else if (value.name === "Silver") {
          setSilverPrice((data) => ({ ...data, ...value }));
          setMultipulePrice((value) => ({ ...value, silver: true }));
        } else if (value.name === "Gold") {
          setGoldPrice((data) => ({ ...data, ...value }));
          setMultipulePrice((value) => ({ ...value, gold: true }));
        }
      });
    }
  }, [whiteLabel.price, whiteLabel.whiteLabelData]);

  const sendFunnelData = () => {
    let data = [];

    if (multipulePrice.bronze === true) {
      data = [...data, bronzePrice];
    }

    if (multipulePrice.silver === true) {
      data = [...data, silverPrice];
    }

    if (multipulePrice.gold === true) {
      data = [...data, goldPrice];
    }

    setPriceData(data);
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("bundle") || accountType.includes("dfy"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"funnel"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  Pricing
                </h6>
                <div className="row bg-table mb-3 justify-content-center">
                  <div className="col-12">
                    <div className="row justify-content-center">
                      <div className="col-4">
                        <div class="custom-control custom-checkbox mb-3 d-flex justify-content-center">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckbronze"
                            name="bronzeCheck"
                            checked={multipulePrice.bronze}
                            onChange={() =>
                              setMultipulePrice((value) => ({
                                ...value,
                                bronze: !value.bronze,
                              }))
                            }
                          />
                          <label
                            class="custom-control-label"
                            for="customCheckbronze"
                          ></label>
                        </div>
                        <div class="card fb-card border-0">
                          <div
                            class="card-header"
                            style={{ background: "#1cb7f2" }}
                          >
                            <h5 className="text-light text-center">Bronze</h5>
                          </div>
                          <div class="card-body">
                            <p className="text-dark">
                              Open a ticket with the following details per
                              video:
                            </p>
                            <ol>
                              <li>Your VideoReel credentials</li>
                              <li>Permission to access your account</li>
                              <li>
                                What is the purpose of this video that you wish
                                to create?
                              </li>
                              <li>Name of the template you wish to use</li>
                              <li> Number of the template you wish to use</li>
                              <li>
                                Screenshot of the template you wish to use
                              </li>
                              <li>
                                Content for the video. Please click on the EDIT
                                option for the template you wish to use and
                                share with us all the text content, logo, music
                                file or Text-to-speech content along with
                                images/video clips you wish to use in your
                                videos.
                              </li>
                            </ol>
                          </div>
                          <div class="card-footer p-0 pt-3"></div>
                        </div>

                        <div className="row justify-content-around pt-3">
                          <div className="col-10 col-md-9 col-lg-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group form-group-mb m-0">
                                {/* <label className="input-label">Name </label> */}
                                <input
                                  className="form-control input-dark-accnt personal-info w-100"
                                  type="text"
                                  name="bronzeName"
                                  placeholder="Enter Name"
                                  value={bronzePrice.name}
                                  onChange={(e) => {
                                    const target = e.target;

                                    setBronzePrice((value) => {
                                      return {
                                        ...value,
                                        name: target.value,
                                      };
                                    });
                                  }}
                                  required
                                  style={{ height: "40px" }}
                                  readOnly={
                                    multipulePrice.bronze ? false : true
                                  }
                                />
                              </div>
                            </form>
                          </div>

                          <div className="col-10 col-md-9 col-lg-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group form-group-mb m-0">
                                {/* <label className="input-label">Name </label> */}
                                <input
                                  className="form-control input-dark-accnt personal-info w-100"
                                  type="text"
                                  name="project"
                                  placeholder="Enter Price"
                                  value={bronzePrice.price}
                                  onChange={(e) => {
                                    const target = e.target;
                                    setBronzePrice((value) => {
                                      return {
                                        ...value,
                                        price: target.value,
                                      };
                                    });
                                  }}
                                  required
                                  style={{ height: "40px" }}
                                  readOnly={
                                    multipulePrice.bronze ? false : true
                                  }
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="custom-control custom-checkbox mb-3 d-flex justify-content-center">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customChecksilver"
                            name="silverCheck"
                            checked={multipulePrice.silver}
                            onChange={() =>
                              setMultipulePrice((value) => ({
                                ...value,
                                silver: !value.silver,
                              }))
                            }
                          />
                          <label
                            class="custom-control-label"
                            for="customChecksilver"
                          ></label>
                        </div>
                        <div class="card fb-card border-0">
                          <div
                            class="card-header"
                            style={{ background: "#1cb7f2" }}
                          >
                            <h5 className="text-light text-center">Silver</h5>
                          </div>
                          <div class="card-body">
                            <p className="text-dark">
                              Open a ticket with the following details per
                              video:
                            </p>
                            <ol>
                              <li>Your VideoReel credentials</li>
                              <li>Permission to access your account</li>
                              <li>
                                What is the purpose of this video that you wish
                                to create?
                              </li>
                              <li>Name of the template you wish to use</li>
                              <li> Number of the template you wish to use</li>
                              <li>
                                Screenshot of the template you wish to use
                              </li>
                              <li>
                                Content for the video. Please click on the EDIT
                                option for the template you wish to use and
                                share with us all the text content, logo, music
                                file or Text-to-speech content along with
                                images/video clips you wish to use in your
                                videos.
                              </li>
                            </ol>
                          </div>

                          <div class="card-footer p-0 pt-3"></div>
                        </div>
                        <div className="row justify-content-around pt-3">
                          <div className="col-10 col-md-9 col-lg-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group form-group-mb m-0">
                                {/* <label className="input-label">Name </label> */}
                                <input
                                  className="form-control input-dark-accnt personal-info w-100"
                                  type="text"
                                  name="silverName"
                                  placeholder="Enter Name"
                                  value={silverPrice.name}
                                  onChange={(e) => {
                                    const target = e.target;
                                    setSilverPrice((value) => {
                                      return {
                                        ...value,
                                        name: target.value,
                                      };
                                    });
                                  }}
                                  required
                                  style={{ height: "40px" }}
                                  readOnly={
                                    multipulePrice.silver ? false : true
                                  }
                                />
                              </div>
                            </form>
                          </div>

                          <div className="col-10 col-md-9 col-lg-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group form-group-mb m-0">
                                {/* <label className="input-label">Name </label> */}
                                <input
                                  className="form-control input-dark-accnt personal-info w-100"
                                  type="text"
                                  name="silverPrice"
                                  placeholder="Enter Price"
                                  value={silverPrice.price}
                                  onChange={(e) => {
                                    const target = e.target;
                                    setSilverPrice((value) => {
                                      return {
                                        ...value,
                                        price: target.value,
                                      };
                                    });
                                  }}
                                  required
                                  style={{ height: "40px" }}
                                  readOnly={
                                    multipulePrice.silver ? false : true
                                  }
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div class="custom-control custom-checkbox mb-3 d-flex justify-content-center">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheckGold"
                            name="checkGold"
                            checked={multipulePrice.gold}
                            onChange={() =>
                              setMultipulePrice((value) => ({
                                ...value,
                                gold: !value.gold,
                              }))
                            }
                          />
                          <label
                            class="custom-control-label"
                            for="customCheckGold"
                          ></label>
                        </div>
                        <div class="card fb-card border-0">
                          <div
                            class="card-header"
                            style={{ background: "#1cb7f2" }}
                          >
                            <h5 className="text-light text-center">Gold</h5>
                          </div>
                          <div class="card-body">
                            <p className="text-dark">
                              Open a ticket with the following details per
                              video:
                            </p>
                            <ol>
                              <li>Your VideoReel credentials</li>
                              <li>Permission to access your account</li>
                              <li>
                                What is the purpose of this video that you wish
                                to create?
                              </li>
                              <li>Name of the template you wish to use</li>
                              <li> Number of the template you wish to use</li>
                              <li>
                                Screenshot of the template you wish to use
                              </li>
                              <li>
                                Content for the video. Please click on the EDIT
                                option for the template you wish to use and
                                share with us all the text content, logo, music
                                file or Text-to-speech content along with
                                images/video clips you wish to use in your
                                videos.
                              </li>
                            </ol>
                          </div>
                          <div class="card-footer p-0 pt-3"></div>
                        </div>

                        <div className="row justify-content-around pt-3">
                          <div className="col-10 col-md-9 col-lg-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group form-group-mb m-0">
                                {/* <label className="input-label">Name </label> */}
                                <input
                                  className="form-control input-dark-accnt personal-info w-100"
                                  type="text"
                                  name="goldPriceName"
                                  placeholder="Enter Name"
                                  value={goldPrice.name}
                                  onChange={(e) => {
                                    const target = e.target;
                                    setGoldPrice((value) => {
                                      return {
                                        ...value,
                                        name: target.value,
                                      };
                                    });
                                  }}
                                  required
                                  style={{ height: "40px" }}
                                  readOnly={multipulePrice.gold ? false : true}
                                />
                              </div>
                            </form>
                          </div>

                          <div className="col-10 col-md-9 col-lg-6">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group form-group-mb m-0">
                                {/* <label className="input-label">Name </label> */}
                                <input
                                  className="form-control input-dark-accnt personal-info w-100"
                                  type="text"
                                  name="goldPrice"
                                  placeholder="Enter Price"
                                  value={goldPrice.price}
                                  onChange={(e) => {
                                    const target = e.target;
                                    setGoldPrice((value) => {
                                      return {
                                        ...value,
                                        price: target.value,
                                      };
                                    });
                                  }}
                                  required
                                  style={{ height: "40px" }}
                                  readOnly={multipulePrice.gold ? false : true}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-11 col-md-8 col-lg-4 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={() => {
                              sendFunnelData();
                              return history.push(`/payment`);
                            }}
                            disabled={
                              multipulePrice.bronze === true ||
                              multipulePrice.silver === true ||
                              multipulePrice.gold === true
                                ? false
                                : true
                            }
                          >
                            Save & Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, { setPriceData })(Funnel);
