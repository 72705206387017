import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect , useSelector, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {resetData} from "../../actions/createPodreel"

function TemplateCount({ user, loading, dashboard }) {
  const [is_client, setIsClient] = useState("");
  const [tempProjectNumber, setTempProjectNumber] = useState(0);
  const dispatch = useDispatch();
  const totalTemplate = useSelector(state => state.video.allProject)
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.role ? false : user.role);
    }
  }, [user, loading]);

  useEffect(() => {
    setTempProjectNumber(
      dashboard.loading || !dashboard.dashboard ? 0 : dashboard.dashboard
    );
  }, [dashboard]);

  const dataReset = () => {
    dispatch(resetData());
  }

  return (
    <>
      <div className="col colmn-2 col-lg-5 col-12">
        <div className="action-btn-wrapper">
          <Link
            to="/my-podcast"
            className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto mb-2"
          >
            <span className="icon text-light pr-3 fs-40">
              {/* {tempProjectNumber
                } */}
                {totalTemplate.length}
            </span>
            <span className="flex-grow-1 text-light pl-3 text-left">
              View All Podcast
            </span>
            <span className="icon arrow" style={{ color: "#424344" }}>
              <svg width="28px" height="28px" viewBox="0 0 28 28">
                <use xlinkHref="./assets/images/right-arrow.svg#rght-arrow" />
              </svg>
            </span>
          </Link>
          {is_client === '3' ? null : (
            <Link
              to="/create-podcast"
              className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto"
              data-toggle
              data-target
              onClick={dataReset}
            >
              <span className="icon text-light pr-3">
                <svg width="41px" height="34px" viewBox="0 0 41 34">
                  <use xlinkHref="./assets/images/ico-video.svg#ico-video" />
                </svg>
              </span>
              <span className="flex-grow-1 text-light pl-3 text-left">
                Create Podcast
              </span>
              <span className="icon arrow" style={{ color: "#424344" }}>
                <svg width="28px" height="28px" viewBox="0 0 28 28">
                  <use xlinkHref="./assets/images/right-arrow.svg#rght-arrow" />
                </svg>
              </span>
            </Link>
          )} 
        </div>
      </div>
    </>
  );
}

TemplateCount.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  dashboard: state.dashboardData,
});

export default connect(mapStateToProps)(TemplateCount);
