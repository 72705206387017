import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { baseURL } from "../../global/global";
import { removeAlert, setAlert } from "../../actions/alert";

import { setGeneralData } from "../../actions/whiteLabel";
import axios from "axios";
import Alert from "../alert/Alert";
import { useHistory } from "react-router-dom";
import WhiteLabelNav from "./WhiteLabelNav";
import { SketchPicker } from "react-color";
import Loader from "../loader/Loader";
import { Redirect } from "react-router-dom";

const WhiteLabel = ({ user, loading, whiteLabel, setGeneralData }) => {
  const [selectedFile, setSelectedFile] = useState({});
  // const [showColorPicker, setShowColorPicker] = useState({
  //   open: false,
  // });

  // const [color, setColor] = useState("");
  const [user_id, setUserId] = useState("");
  const [loader, setLoader] = useState(false);
  const [general, setGeneral] = useState({
    name: "",
    logo: "",
    color: "",
    app_name: "",
    title: "",
    description: "",
    support: "",
    txt_record: "",
  });
  const [accountType, setAccountType] = useState([]);
  const history = useHistory();
  // const wrapperRef = useRef(null);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  useEffect(() => {
    if (
      Object.keys(whiteLabel.general).length > 0 &&
      whiteLabel.general.constructor === Object
    ) {
      setGeneral({ ...whiteLabel.general });
    } else if (whiteLabel.whiteLabelData.length > 0) {
      setGeneral({ ...whiteLabel.whiteLabelData[0].general });
    }
  }, [whiteLabel.whiteLabelData, whiteLabel.general]);

  // // Handle click outside ColorPicker Hide
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       setShowColorPicker({ open: false });
  //     }
  //   }

  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [wrapperRef]);

  const setAllGeneralValue = (e) => {
    let target = e.target;
    setGeneral((value) => ({ ...value, [target.name]: target.value }));
  };

  const uploadBranding = async (e) => {
    if (!e.target.files[0]) return;

    const fileSize = e.target.files[0].size / (1024 * 1024);
    const fileName = e.target.files[0].name;

    if (fileSize <= 5) {
      const fileExtensionArray = fileName.split(".");
      const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
      if (
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      ) {
        setLoader(true);
        setSelectedFile(e.target.files[0]);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        try {
          const res = await axios.post(
            `${baseURL}/api/upload-logo`,
            formData,
            config
          );

          if (res.data.status === true) {
            setGeneral((value) => ({ ...value, logo: res.data.data }));
            setSelectedFile({});
            setLoader(false);
          } else if (res.data.status === false) {
            setLoader(false);
          }
        } catch (err) {
          console.log(err.response);
          setLoader(false);
        }
      } else {
        setAlert(
          "The file extension you are trying to upload is not allowed, extension allowed only(png, jpeg, jpg)",
          "danger"
        );
      }
    } else {
      setAlert("You cannot upload more then 5MB in size", "danger");
    }
  };

  const setGeneralDataInRedux = () => {
    setLoader(true);

    // setFileData(selectedFile);
    setGeneralData(general);

    setLoader(false);
  };

  if (accountType.length > 0) {
    if (!(accountType.includes("business") || accountType.includes("bundle"))) {
      return <Redirect to="/dashboard" />;
    }
  }

  // Color Chanage
  // const handleChangeComplete = (colorChange) => {
  //   // setBackground(colorChange.hex);
  //   setGeneral((value) => ({ ...value, color: colorChange.hex }));
  // };

  // const popover = {
  //   position: "fixed",
  //   zIndex: "1000",
  //   left: "400px",
  //   top: "240px",
  // };
  // const cover = {
  //   position: "relative",
  //   top: "0px",
  //   right: "0px",
  //   bottom: "0px",
  //   left: "0px",
  // };

  // const swatch = {
  //   boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
  //   cursor: "pointer",
  //   width: "50px",
  //   height: "30px",
  //   borderRadius: "5px",
  // };

  return (
    <ContractStyle>
      <div className="contract-assets">
        <section id="contract-assets-content">
          <div className="container-fluid">
            <div className="row justify-content-center align-items-between fb-contract mt-5">
              <WhiteLabelNav activeTab={"whitelabel"} />
              <div className="col-11 mb-4">
                <h6
                  className="text-white mb-0 mt-1 mb-2 Account-Management-headings"
                  style={{ fontSize: "18px !important" }}
                >
                  General
                </h6>
                <div className="row bg-table mb-3 justify-content-center">
                  <div className="col-10">
                    <div className="row justify-content-center">
                      {" "}
                      <div className="col-4 ml-0 pl-1">
                        {loader === true ? (
                          <Loader />
                        ) : (
                          <img
                            alt=""
                            className="proj_img poster"
                            // src="https://picsum.photos/200/300"
                            src={general.logo}
                            // onClick={() =>
                            //   previewVideo(template.preview_video_url)
                            // }
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "20vh",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="col-8 p-0"
                        style={{
                          height: "20vh",
                          width: "100%",
                          backgroundColor: "#28A745",
                          border: "2px white dashed",
                        }}
                      >
                        <form
                          onSubmit={(e) => e.target.preventDefault()}
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <div className="upload-btn-wrapper">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                              <button
                                style={{
                                  backgroundColor: "#28A745",
                                  color: "white",
                                  border: "none",
                                  fontSize: "1rem",
                                }}
                              >
                                {Object.keys(selectedFile).length === 0 &&
                                selectedFile.constructor === Object
                                  ? "Upload your Audio File (MP3 upto 100MB is allowed)"
                                  : selectedFile.name}
                              </button>
                              <i
                                className="fa fa-cloud-upload"
                                aria-hidden="true"
                                style={{
                                  display: "block",
                                  color: "white",
                                  fontSize: "1.7rem",
                                  paddingTop: "10px",
                                }}
                              ></i>
                            </div>
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              name="file"
                              style={{
                                width: "100%",
                                height: "100%",
                                padding: "0",
                                cursor: "pointer",
                              }}
                              // onChange={(e) => {
                              //   uploadBranding(e);
                              // }}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Domain Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="name"
                            placeholder="Enter your Domain Name"
                            value={general.name}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">App Name </label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="app_name"
                            placeholder="Enter your App Name"
                            value={general.app_name}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Title</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="title"
                            placeholder="Enter your title"
                            value={general.title}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Description</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="description"
                            placeholder="Enter Description"
                            value={general.description}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Support</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="support"
                            placeholder="Enter Support"
                            value={general.support}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-6 p-1">
                        <div className="form-group form-group-mb">
                          <label className="input-label">Text Record</label>
                          <input
                            className="form-control input-dark-accnt personal-info  w-100"
                            type="text"
                            name="txt_record"
                            placeholder="Enter Text Record"
                            value={general.txt_record}
                            onChange={(e) => setAllGeneralValue(e)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="row d-flex justify-content-center align-items-center">
                      <div
                        className="col-12 mx-0 p-0"
                        style={{
                          font: "400 14px/1.19 'Montserrat', Sans-serif",
                          color: "#9e9e9e",
                          textAlign: "center",
                        }}
                      >
                        Color
                      </div>
                      <div
                        className="col-2 mx-0 p-0 mt-2"
                        style={swatch}
                        onClick={() => {
                          setShowColorPicker((value) => ({
                            ...value,
                            open: true,
                          }));
                        }}
                      >
                        <div
                          style={{
                            background: `${
                              general.color === "" ? "white" : general.color
                            }`,
                            height: "100%",
                            borderRadius: "5px",
                            border: "1px solid black",
                          }}
                        />
                       
                    </div> */}

                    <div className="row justify-content-center">
                      <div className="col-11 col-md-8 col-lg-4 text-center mt-3">
                        {loader ? (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            type="button"
                            disabled
                            style={{
                              background: "#1cb7f2",
                              font: "16px",
                              color: "white",
                            }}
                          >
                            <span
                              className="spinner-border spinner-border-sm text-light mx-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <strong>Loading...</strong>
                          </button>
                        ) : (
                          <button
                            className="btn btn-3 accnt-mngmt-btn mt-4"
                            onClick={() => {
                              setGeneralDataInRedux();
                              return history.push(`/temp`);
                            }}
                            disabled={
                              general.logo === "" ||
                              general.name === "" ||
                              general.support === "" ||
                              general.title === "" ||
                              general.txt_record === "" ||
                              general.app_name === "" ||
                              general.description === "" ||
                              general.color === ""
                                ? true
                                : false
                            }
                          >
                            Save & Next
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* {showColorPicker.open ? (
          <div style={popover} ref={wrapperRef}>
            <div
              style={cover}
              onClick={() =>
                setShowColorPicker((value) => ({ ...value, open: false }))
              }
            />
            <SketchPicker
              color={general.color}
              onChange={handleChangeComplete}
            />
          </div>
        ) : null} */}
      </div>
    </ContractStyle>
  );
};

const ContractStyle = styled.div`
  .btn-3 {
    max-width: 100% !important;
  }

  a {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .upload-btn-wrapper .btn {
    background: #171717 !important;
    color: white;
    cursor: pointer;
  }
`;

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  whiteLabel: state.whiteLabel,
});

export default connect(mapStateToProps, {
  removeAlert,
  setAlert,
  // getBrandingDataFromServer,
  setGeneralData,
})(WhiteLabel);
