import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Profile from "./components/Profile/Profile";
import Account from "./components/Account/Account";
import Login from "./components/auth/Login";
import Forgot from "./components/auth/Forgot";
import ResetPassword from "./components/auth/ResetPassword";
import PrivateRoute from "./components/routing/PrivateRoute";
import UploadSection from "./components/createPodcast/UploadSection";
import ProfileSection from "./components/createPodcast/ProfileSection";
import CustomizeSection from "./components/createPodcast/CustomizeSection";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import Privacy from "./components/privacy/Privacy.jsx";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";

import MyVideo from "./components/CreateVideos/myVideo/MyVideo";
import Support from "./components/support/Support";
import SupportDetail from "./components/support/SupportDetail";
import SupportArticle from "./components/support/SupportArticle";

import LoginLoader from "./components/loginLoader/LoginLoader";
import Upgrades from "./components/upgrade/Upgrades";
import MasterLogin from "./components/masterLogin/MasterLogin";
import WhiteLabel from "./components/whiteLabel/WhiteLabel";
import Temp from "./components/whiteLabel/Temp";
import Funnel from "./components/whiteLabel/Funnel";
import Payment from "./components/whiteLabel/Payment";
import SMTP from "./components/whiteLabel/SMTP";
import CreatePodcast from "./components/createPodcast/CreatePodcast";
import Integration from "./components/integration/Integration";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    if (!window.location.href.includes("aksjry73984trkje")) {
      store.dispatch(loadUser());
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {window.location.href.lastIndexOf("/privacyPolicy") > -1 ||
        window.location.href.lastIndexOf("/aksjry73984trkje") > -1 ? null : (
          <Header />
        )}
        <Route exact path="/" component={Login} />

        <Switch>
          <Route exact path="/forget-password" component={Forgot} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/master-login" component={MasterLogin} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/account" component={Account} />
          <PrivateRoute exact path="/privacy" component={Privacy} />
          <PrivateRoute exact path="/help-and-support" component={Support} />
          <PrivateRoute
            exact
            path="/create-podcast"
            component={CreatePodcast}
          />
          <PrivateRoute
            exact
            path="/ProfileSection"
            component={ProfileSection}
          />
          <PrivateRoute exact path="/UploadSection" component={UploadSection} />
          <PrivateRoute
            exact
            path="/CustomizeSection"
            component={CustomizeSection}
          />
          <PrivateRoute
            exact
            path="/support-detail"
            component={SupportDetail}
          />
          <PrivateRoute
            exact
            path="/support-article"
            component={SupportArticle}
          />
          <PrivateRoute exact path="/upgrades" component={Upgrades} />
          <PrivateRoute exact path="/integration" component={Integration} />
          <PrivateRoute exact path="/whitelabel" component={WhiteLabel} />
          <PrivateRoute exact path="/temp" component={Temp} />
          <PrivateRoute exact path="/funnel" component={Funnel} />
          <PrivateRoute exact path="/payment" component={Payment} />
          <PrivateRoute exact path="/smtp" component={SMTP} />
          <PrivateRoute exact path="/my-podcast" component={MyVideo} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Redirect from="*" to="/" />
        </Switch>
        {window.location.href.lastIndexOf("/privacyPolicy") > -1 ||
        window.location.href.lastIndexOf("/aksjry73984trkje") > -1 ? null : (
          <Footer />
        )}
        <LoginLoader />
      </Router>
    </Provider>
  );
}

export default App;
