import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import CreateHead from "./CreateHead";
import { baseURL } from "../../global/global";
import Caption from "./CustomizeDetails/Captions/Caption";
import General from "./CustomizeDetails/General/General";
import WaveForm from "./CustomizeDetails/WaveForm/Waveform";
import Image from "./CustomizeDetails/Image/Image";
import Text from "./CustomizeDetails/Text/Text";
import Progress from "./CustomizeDetails/Progress/Progress";
import Preview from "./CustomizeDetails/Preview/Preview";
import { connect, useSelector } from "react-redux";
import axios from "axios";

const CustomizeSection = (props) => {
  const history = useHistory();
  const user_id = useSelector((state) => state.auth.user.user_id);
  const campaign_id = useSelector(
    (state) => state.createPodreel.trimmed_audio_data.campaign_id
  );

  const podcastName = useSelector(
    (state) => state.createPodreel.audio_file_data.campaignName
  );
  const trimmedAudio = useSelector(
    (state) => state.createPodreel.trimmed_audio_data.trimaudio
  );
  const startTime = useSelector(
    (state) => state.createPodreel.duration.startTime
  );
  const endTime = useSelector((state) => state.createPodreel.duration.endTime);
  const totalDuration = useSelector(
    (state) => state.createPodreel.duration.totalDuration
  );

  const name = useSelector(
    (state) => state.createPodreel.audio_data.audioAddCaption
  );
  // const code = useSelector(state => state.createPodreel.audio_data.audioAddCaption.code);
  // const add_caption = useSelector(state => state.createPodreel.audio_data.audioAddCaption.add_caption);

  const name1 = useSelector(
    (state) => state.createPodreel.audio_data.audioTranslateCaption
  );
  // const code1 = useSelector(state => state.createPodreel.audio_data.audioTranslateCaption.code);
  // const add_caption1 = useSelector(state => state.createPodreel.audio_data.audioTranslateCaption.translate_caption);

  const dimension = useSelector(
    (state) => state.createPodreel.customize.general.dimension
  );
  const customizeWidth = useSelector(
    (state) => state.createPodreel.customize.general.width
  );
  const customizeHeight = useSelector(
    (state) => state.createPodreel.customize.general.height
  );
  const customizeBackColor = useSelector(
    (state) => state.createPodreel.customize.general.backgroundColor
  );

  const waveWidth = useSelector(
    (state) => state.createPodreel.customize.waveform.width
  );
  const waveHeight = useSelector(
    (state) => state.createPodreel.customize.waveform.height
  );
  const wavePosX = useSelector(
    (state) => state.createPodreel.customize.waveform.posX
  );
  const wavePosY = useSelector(
    (state) => state.createPodreel.customize.waveform.posY
  );
  const waveColor = useSelector(
    (state) => state.createPodreel.customize.waveform.color
  );
  const waveName = useSelector(
    (state) => state.createPodreel.customize.waveform.name
  );

  const img = useSelector((state) => state.createPodreel.customize.image);

  const txt = useSelector((state) => state.createPodreel.customize.text);

  const txtSize = useSelector((state) => state.createPodreel.customize.text);

  const progressColor = useSelector(
    (state) => state.createPodreel.customize.progress.color
  );

  const transcribeJson = useSelector(
    (state) => state.createPodreel.generated_Captions.captions
  );
  const translateJson = useSelector(
    (state) => state.createPodreel.translated_Captions.captions
  );
  const [loader, setLoader] = useState(false);

  const renderVideo = () => {
    setLoader(true);
    const created_json = {
      podcast_name: podcastName,
      audio_file_url: trimmedAudio,
      start_time: startTime,
      end_time: endTime,
      duration: totalDuration,
      audio_add_caption: [
        {
          add_caption: name === null ? "" : name.add_caption,
          code: name === null ? "" : name.code,
          name: name === null ? "" : name.name,
        },
      ],
      audio_translate_caption: [
        {
          translate_caption: name1 === null ? "" : name1.add_caption,
          code: name1 === null ? "" : name1.code,
          name: name1 === null ? "" : name1.name,
        },
      ],
      customize: {
        general: [
          {
            dimension: dimension,
            width: customizeWidth,
            height: customizeHeight,
            backgroundcolor: customizeBackColor,
          },
        ],
        waveform: [
          {
            posx: wavePosX,
            posy: wavePosY,
            height: waveHeight,
            width: waveWidth,
            color: "#fff",
            mode: waveName,
          },
        ],
        progress: [
          {
            color: progressColor,
          },
        ],
        image: img,
        //   {
        //     posx: "20",
        //     posy: "30",
        //     height: "200",
        //     width: "300",podcast-create
        //     url: `${img.length > 0 ? img[img.length - 1].url : ""}`,
        //   },
        // ],
        text: txt,
      },
    };

    const formData = new FormData();

    formData.append("user_id", user_id);
    formData.append("created_json", JSON.stringify(created_json));
    formData.append("campaignid", campaign_id);
    formData.append("transcribe_json", JSON.stringify(transcribeJson));
    formData.append("translate_json", JSON.stringify(translateJson));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(`${baseURL}/api/podcast-create`, formData, config)
      .then((res) => {
        console.log(res.data)
        if (res.data.status === true) {
          setLoader(false);
          history.push("/dashboard");
        } else if (res.data.status === false) {
          history.push("/dashboard");
          setLoader(false);
        }
      });
  };

  //   axios
  //     .post(`${baseURL}/api/podcast-create`, formData, config)
  //     .then((res) => {
  //       if (res.data.status === true) {
  //         setLoader(false);
  //         history.push("/dashboard");
  //       } else {
  //         setLoader(false);

  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred:", error);
  //       setLoader(false);
  //     });
  // }
  return (
    <>
      <Helmet>
        <title> PodReel | CustomizeSection</title>
      </Helmet>
      <CreateHead />
      <section className="create-prodcast-sec my-create-prodcast-sec">
        <div className="container-fluid px-2 px-md-5">
          <div className="row">
            <div className="col-lg-12 col-md-12 mx-auto">
              <div className="create-tab">
                <nav className="mb-5">
                  <div
                    className="nav nav-tabs d-flex justify-content-between"
                    id="nav-tab"
                    role="tablist"
                  >
                    <Link className="nav-item nav-link" to="/UploadSection">
                      <span>1</span>Upload
                    </Link>
                    <Link className="nav-item nav-link" to="/ProfileSection">
                      <span>2</span>Audio
                    </Link>

                    <a
                      className="nav-item nav-link active"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#nav-contact"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="true"
                    >
                      <span>3</span> Customize
                    </a>
                  </div>
                </nav>
                <div
                  className="tab-content mt-lg-5 mt-md-5"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane my-tab-pane  text-white fade show active"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <div className="customize-box my-customize-box">
                      <div className="row">
                        <div className="col-12 col-lg-2">
                          <div
                            className="nav flex-column "
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <a
                              className="nav-link active"
                              id="v-pills-caption-tab"
                              data-toggle="pill"
                              href="#v-pills-caption"
                              role="tab"
                              aria-controls="v-pills-caption"
                              aria-selected="true"
                            >
                              CAPTION
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-home-tab"
                              data-toggle="pill"
                              href="#v-pills-home"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="false"
                            >
                              GENERAL
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-profile-tab"
                              data-toggle="pill"
                              href="#v-pills-profile"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected="false"
                            >
                              WAVEFORM
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-messages-tab"
                              data-toggle="pill"
                              href="#v-pills-messages"
                              role="tab"
                              aria-controls="v-pills-messages"
                              aria-selected="false"
                            >
                              MEDIA
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-settings-tab"
                              data-toggle="pill"
                              href="#v-pills-settings"
                              role="tab"
                              aria-controls="v-pills-settings"
                              aria-selected="false"
                            >
                              TEXT
                            </a>
                            <a
                              className="nav-link"
                              id="v-pills-progress-tab"
                              data-toggle="pill"
                              href="#v-pills-progress"
                              role="tab"
                              aria-controls="v-pills-progress"
                              aria-selected="false"
                            >
                              PROGRESS
                            </a>
                          </div>
                        </div>

                        <div className="col-12 col-lg-5">
                          <div className="tab-content" id="v-pills-tabContent">
                            <Caption />
                            <General customize={props.customize} />
                            <WaveForm />
                            <Image />
                            <Text />
                            <Progress />
                          </div>
                        </div>
                        {/* style={{backgroundColor:backColor, backgroundImage:`url(${imgUrl})`, backgroundSize:"cover", backgroundRepeat:"no-repeat", backgroundPosition:"center"}} */}
                        <div className="col-12 col-lg-5">
                          {props.customize ? (
                            <Preview customize={props.customize} />
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-md-12">
                          <div
                            className="render-btn text-end"
                            style={
                              dimension === "9:16"
                                ? { marginTop: "100px" }
                                : null
                            }
                          >
                            {loader ? (
                              <div
                                className="d-flex justify-content-center"
                                style={{
                                  background: "#ea5e5f",
                                  width: "12%",
                                  float: "right",
                                  padding: "3px 5px",
                                }}
                              >
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <Link onClick={renderVideo}>RENDER VIDEO</Link>
                            )}

                            {/* {loader === false ? (
                              <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : null} */}
                            {/* RENDER VIDEO */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  customize: state.createPodreel.customize,
});
export default connect(mapStateToProps)(CustomizeSection);
