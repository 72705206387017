import {
  SAVE_UPLOAD_URL,
  AUDIO_ADD_CAPTION,
  AUDIO_TRANSLATE_CAPTION,
  TRIMMED_AUDIO_DATA,
  SAVE_GENERAL_DATA,
  SAVE_IMAGE_DATA,
  SAVE_PROGRESS_DATA,
  SAVE_TEXT_DATA,
  SAVE_WAVEFORM_DATA,
  GENERATED_CAPTION,
  TRANSLATED_CAPTION,
  UPDATED_TRANSLATED_CAPTION,
  UPDATED_GENERATED_CAPTION,
  DURATION,
  EDITED_DATA,
  RESET_DATA,
  ADD_TEXT,
  ADD_TEXT_PROPERTY,
  ADD_TEXT_COLOR,
  ADD_TEXT_FONT,
  WAVE_POS,
  IMG_POS,
  CHANGE_IMG_SELECTED,
  TEXT_POSITION,
  REMOVE_WAVEFORM_DATA,
  FETCH_SOCIAL_ACCOUNTS,
  ADD_FONT_SIZE,
} from "./Types";

import axios from "axios";
import { baseURL } from "../global/global";
// import { setAlert } from "../";

export const saveUploadAudioUrl = (data) => (dispatch) => {
  dispatch({ type: SAVE_UPLOAD_URL, payload: data });
};

export const editedData = (data) => (dispatch) => {
  dispatch({ type: EDITED_DATA, payload: data });
};

export const saveAudioCaption = (data) => (dispatch) => {
  dispatch({ type: AUDIO_ADD_CAPTION, payload: data });
};

export const saveTransAudioCaption = (data) => (dispatch) => {
  dispatch({ type: AUDIO_TRANSLATE_CAPTION, payload: data });
};

export const trimmedAudioData = (data) => (dispatch) => {
  dispatch({ type: TRIMMED_AUDIO_DATA, payload: data });
};

export const duration = (data) => (dispatch) => {
  dispatch({ type: DURATION, payload: data });
};

export const generatedCaptions = (data) => (dispatch) => {
  dispatch({ type: GENERATED_CAPTION, payload: data });
};

export const translatedCaptions = (data) => (dispatch) => {
  dispatch({ type: TRANSLATED_CAPTION, payload: data });
};

export const updatedTranslatedCaptions = (data, index) => (dispatch) => {
  dispatch({
    type: UPDATED_TRANSLATED_CAPTION,
    payload: JSON.stringify({ data: data, index: index }),
  });
};

export const updatedGeneratedCaptions = (data, index) => (dispatch) => {
  dispatch({
    type: UPDATED_GENERATED_CAPTION,
    payload: JSON.stringify({ data: data, index: index }),
  });
};

export const saveGeneralData = (data) => (dispatch) => {
  dispatch({ type: SAVE_GENERAL_DATA, payload: data });
};

export const saveImageData = (data) => (dispatch, getState) => {
  // const oldArray = getState().createPodreel.customize.image;
  // oldArray.push(data);
  dispatch({ type: SAVE_IMAGE_DATA, payload: data });
};

export const saveTextData = (data) => (dispatch, getState) => {
  const oldArray = getState().createPodreel.customize.text;
  oldArray.push(data);
  dispatch({ type: SAVE_TEXT_DATA, payload: oldArray });
};

export const saveProgressData = (data) => (dispatch) => {
  dispatch({ type: SAVE_PROGRESS_DATA, payload: data });
};

export const addText = (data, index) => (dispatch) => {
  dispatch({ type: ADD_TEXT, payload: { data: data, index: index } });
};

export const addTextProperty = (data, index) => (dispatch) => {
  dispatch({ type: ADD_TEXT_PROPERTY, payload: { data: data, index: index } });
};

export const addTextColor = (data, index) => (dispatch) => {
  dispatch({ type: ADD_TEXT_COLOR, payload: { data: data, index: index } });
};

export const addFontSize = (data, index) => (dispatch) => {
  dispatch({ type: ADD_FONT_SIZE, payload: { data: data, index: index } });
};

export const addTextFont = (data, index) => (dispatch) => {
  dispatch({ type: ADD_TEXT_FONT, payload: { data: data, index: index } });
};

export const wavePos = (data, index) => (dispatch) => {
  dispatch({ type: WAVE_POS, payload: { data: data, index: index } });
};

export const imgPos = (data, index) => (dispatch) => {
  dispatch({ type: IMG_POS, payload: { data: data, index: index } });
};

export const changeImgSelected = (data, index) => (dispatch) => {
  dispatch({
    type: CHANGE_IMG_SELECTED,
    payload: { data: data, index: index },
  });
};

export const textPosition = (data, index) => (dispatch) => {
  dispatch({ type: TEXT_POSITION, payload: { data: data, index: index } });
};

export const saveWaveformData = (data) => (dispatch) => {
  dispatch({ type: SAVE_WAVEFORM_DATA, payload: data });
};

export const removeWaveformData = (data) => (dispatch) => {
  dispatch({ type: REMOVE_WAVEFORM_DATA, payload: data });
};

// export const fetchSocialAccounts = (data) => (dispatch) => {
//   dispatch({ type: FETCH_SOCIAL_ACCOUNTS, payload: data });
// };
export const fetchSocialAccounts = (userId) => (dispatch, getState) => {
  axios({
    method: "POST",
    url: `${baseURL}api/fetch-social-accounts`,
    data: { user_id: userId },
    headers: {
      "Content-Type": "application/json",
      Authorization: getState().auth.token,
    },
  })
    .then((res) => {
      if (res.data.status === true) {
        // dispatch(addSocialAccounts(res.data.data));
        dispatch({ type: FETCH_SOCIAL_ACCOUNTS, payload: res.data.data });
      }
    })
    .catch((err) => {});
};

export const resetData = (data) => (dispatch) => {
  dispatch({ type: RESET_DATA });
};
