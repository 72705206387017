import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import video from "./video";
import loader from "./loader";
import accountManagement from "./accountManagement";
import dashboardData from "./dashboard";
import supportData from "./support";
import whiteLabel from "./whiteLabel";
import createPodreel from "./createPodreel";
import integration from "./integration";


export default combineReducers({
  auth,
  alert,
  video,
  loader,
  accountManagement,
  dashboardData,
  supportData,
  whiteLabel,
  createPodreel,
  integration
});
