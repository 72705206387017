import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  addClientAccount,
  addTeamAccount,
} from "../../actions/accountManagement";
import { setLoader } from "../../actions/loader";
import { setAlert } from "../../actions/alert";

function AddAccount({
  auth: { user, loading },
  addClientAccount,
  addTeamAccount,
  setLoader,
  loader,
  showAlert,
}) {
  const [user_id, setUserId] = useState("");
  const [accountDetail, setAccountDetail] = useState({
    firstName: "",
    password: "",
    email: "",
    accountType: "client",
  });
  const [passwordError, setPasswordError] = useState("");
  const [check, setCheck] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  // Fetch Id
  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  const addAccountDetail = (e) => {
    setAccountDetail({
      ...accountDetail,
      [e.target.name]: e.target.value,
    });
  };

  const validatePassword = (password) => {
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
    if (!password) {
      return "Password is required.";
    }
    if (!pattern.test(password)) {
      return "Password must include at least 1 uppercase, 1 lowercase, 1 number, 1 special character and should be at least 8 characters long.";
    }
    return "";
  };

  // Submit Form
  const addAccount = async (e) => {
    e.preventDefault();
    setCheck(true);
    showAlert(true);
    setLoader();

    const error = validatePassword(accountDetail.password);
    if (error) {
      setPasswordError(error);
      setCheck(false);
      setLoader();
      return;
    } else {
      setPasswordError("");
    }

    const data = {
      name: accountDetail.firstName,
      email: accountDetail.email,
      password: accountDetail.password,
      type: accountDetail.accountType,
      user_id: user_id
    };

    if (accountDetail.accountType === "client") {
      await addClientAccount(data);
    } else if (accountDetail.accountType === "team") {
      await addTeamAccount(data);
    }

    setLoader();
    setAccountDetail({
      firstName: "",
      password: "",
      email: "",
      accountType: "client",
    });
    setCheck(false);
  };

  return (
    <div className="container px-4 mt-5 mb-0">
      <h6 className="text-white mb-0 mt-5 mb-2 Account-Management-headings" style={{ fontSize: "18px !important" }}>
        Add Account
      </h6>
      <div className="bg-table my-bg-table">
        <form onSubmit={addAccount}>
          <div className="row d-flex justify-content-center w-100 mx-auto">
            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Account Type </label>
                <select
                  className="form-control input-account-type p-accnt"
                  id="Account_selector"
                  name="accountType"
                  value={accountDetail.accountType}
                  onChange={addAccountDetail}
                >
                  <option value="team">Team Member</option>
                  <option value="client">Client Account</option>
                </select>
              </div>
            </div>
            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Name </label>
                <input
                  className="form-control input-dark-accnt personal-info  w-100"
                  type="text"
                  name="firstName"
                  placeholder="Name"
                  value={accountDetail.firstName}
                  onChange={addAccountDetail}
                  required
                />
              </div>
            </div>

            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Password </label>
                <input
                  className="form-control input-dark-accnt personal-info w-100"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={accountDetail.password}
                  required
                  onChange={addAccountDetail}
                  autoComplete="off"
                />
                <span
                  className="input-show"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <img src="./assets/images/show.svg" alt="" className="show-svg" />
                </span>
                {passwordError && <div className="text-danger">{passwordError}</div>}
              </div>
            </div>
            <div className="col col-lg-6 col-12 p-1">
              <div className="form-group form-group-mb">
                <label className="input-label">Email Address </label>
                <input
                  className="form-control input-dark-accnt personal-info"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={accountDetail.email}
                  onChange={addAccountDetail}
                  autoComplete="off"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col col-md-12 text-center mt-3">
            {loader && check ? (
              <button
                className="btn btn-3 accnt-mngmt-btn mt-4"
                type="button"
                disabled
                style={{
                  background: "#1cb7f2",
                  font: "16px",
                  color: "white",
                }}
              >
                <span
                  className="spinner-border spinner-border-sm text-light mx-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <strong>Loading...</strong>
              </button>
            ) : (
              <button className="btn btn-3 accnt-mngmt-btn mt-4">Save</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

AddAccount.propTypes = {
  addClientAccount: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  addTeamAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loader: PropTypes.bool,
  showAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  addClientAccount,
  setLoader,
  addTeamAccount,
})(AddAccount);
