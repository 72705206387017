import React, { useState } from "react";
import { Link } from "react-router-dom";

function WatchNow() {


  return (
    <>
      <div className="col colmn-1 offset-xl-1 col-xl-6 col-lg-7 col-md-8 col-12 mb-lg-0 mb-5 px-xl-2">
        <div className="title-area text-lg-left text-center mb-2">
          <h1 className="big-heading">WELCOME</h1>
          <h2 className="big-heading font-weight-bold text-white">
            TO {"PODREEL"}
          </h2>
        </div>
        <div
          className="desc text-lg-left text-center w-100 mx-lg-0 mx-auto mb-3 pb-3"
          style={{ maxWidth: "680px" }}
        >
          <p
            className="text-white font-weight-lighter"
            style={{ lineHeight: "1.75" }}
          >
            Turn any audio or podcast episode into gorgeous video for your social media within minutes.
          </p>
        </div>
        <div className="intro-btn text-lg-left text-center">
          <Link
            to="#"
            className=""
            data-toggle="modal"
            data-target="#watch-video"
          >
            <i className="fa fa-play pr-1" aria-hidden="true" />
            <span
              className="pl-1 text-white"
              style={{ fontWeight: 600, fontSize: "18px" }}
            >
              Watch Now
            </span>
          </Link>
        </div>
      </div>
    </>
  );
}


export default WatchNow;
