import React, { useState, useEffect } from "react";
import SupportSidenav from "./SupportSidenav";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../loader/Loader";

const SupportArticle = ({
  support: { loading, support_articles },
  location,
  auth,
}) => {
  const [articles, setArticles] = useState({});
  const [is_client, setIsClient] = useState(false);
  const category = queryString.parse(location.search).category;
  const history = useHistory();

  useEffect(() => {
    if (auth.user) {
      setIsClient(
        auth.loading || !auth.user.is_client ? false : auth.user.is_client
      );
    }
  }, [auth.user, auth.loading]);

  useEffect(() => {
    if (support_articles.length > 0) {
      setArticles(
        loading || !support_articles
          ? {}
          : support_articles.find((article) => {
              return article.id === category;
            })
      );
    }
  }, [loading, support_articles, category]);

  if (is_client === true) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      {articles === undefined || support_articles.length < 1 ? (
        <section
          id="all-sup-tpc"
          className="bg-grad bg-dark tpc-section"
          style={{ display: "block" }}
        >
          <div className="tpc-container container-fluid px-4">
            <div className="row align-items-center" style={{ height: "60vh" }}>
              <div className="col colmn-1 col-12 mb-5 pb-4">
                <div className="title-area text-center">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section
          id="all-sup-tpc"
          className="bg-grad bg-dark tpc-section"
          style={{ display: "block" }}
        >
          <div className="tpc-container container-fluid px-4">
            <div className="row align-items-start">
              <div className="col colmn-1 col-12 mb-5 pb-4">
                <div className="title-area">
                  <nav className="mb-2" aria-label="Page breadcrumb">
                    <ol
                      className="breadcrumb p-0 mb-0"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/help-and-support" className="tpc-sm-cap">
                          Support
                        </Link>{" "}
                        /{" "}
                        <a
                          href="!#"
                          className="text-white"
                          onClick={(e) => {
                            e.preventDefault();
                            // return history.push(
                            //   `/support-article?category=${articles.id}`
                            // );
                          }}
                        >
                          {articles.name}
                        </a>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="tpc-lg-cap text-white"> Getting Started </h2>
                </div>
              </div>
              <SupportSidenav />
              <div className="col colmn-3 col-lg-8 col-md-7 col-sm-11 col-12 mx-auto">
                {Object.keys(articles).length === 0 &&
                articles.constructor === Object
                  ? null
                  : articles.items.map((article, index) => (
                      <div
                        className="art-tpc tpc-wrapper w-100"
                        key={index}
                        onClick={(e) => {
                          e.preventDefault();
                          return history.push(
                            `/support-detail?category=${articles.id}&item=${article.id}`
                          );
                        }}
                      >
                        <div className="img-wrapper mb-3">
                          <img
                            className="img-fluid"
                            src={article.feature_url}
                            alt="Thumbnail"
                          />
                        </div>
                        <div className="tpc-details">
                          {/* <p
                            className="tpc-cat tpc-sm-cap mb-2"
                            style={{ letterSpacing: "1.2px" }}
                          >
                            TOPIC CATEGORY
                          </p> */}
                          <h5 className="tpc-title md-txt text-white article-title-hover">
                            {article.title}
                          </h5>
                          <div className="tpc-meta d-flex">
                            <p className="tpc-date mr-3 mb-0">
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              />
                              <span className="date ml-2">
                                {article.created}
                              </span>
                            </p>
                            {/* <p className="tpc-type">
                              <i
                                className="fa fa-folder-o"
                                aria-hidden="true"
                              />
                              <span className="type ml-2">ARTICLE</span>
                            </p> */}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  support: state.supportData,
  auth: state.auth,
});

SupportArticle.propTypes = {
  support: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SupportArticle);
