import React, { useState, useEffect } from "react";
import Profile from "./Profile";
import { Link, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { resetData } from "../../actions/createPodreel";
import { RxHamburgerMenu } from "react-icons/rx";

function NavSection({ auth: { user, loading } }) {
  const [active, setActive] = useState("");
  const [is_client, setIsClient] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.role ? null : user.role);
    }
  }, [user, loading]);

  useEffect(() => {
    if (location) {
      // if (location.pathname === "/integration") {
      //   setActive("integrations");
      // } else
      if (location.pathname === "/my-videos") {
        setActive("my_project");
      } else if (location.pathname === "/dashboard") {
        setActive("dashboard");
      } else if (location.pathname === "/integration") {
        setActive("integration");
      } else {
        setActive("");
      }
    }
  }, [location]);

  const dataReset = () => {
    dispatch(resetData());
  };

  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#app-nav"
        aria-controls="app-nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {/* <span className="navbar-toggler-icon" /> */}
        <RxHamburgerMenu style={{color:"#fff"}} />
      </button>
      <div
        id="app-nav"
        className="collapse navbar-collapse justify-content-end"
      >
        <ul className="navbar-nav align-items-lg-center">
          <li className="nav-item nav-item-1">
            <Link
              className={`nav-link ${active === "dashboard" ? "active" : ""}`}
              to="/dashboard"
              style={
                location.pathname === "/dashboard"
                  ? { color: "#ff949f" }
                  : { color: "#fff" }
              }
            >
              Dashboard
            </Link>
          </li>

          {is_client === "3" ? null : (
            <li className="nav-item nav-item-2">
              <Link
                className={`nav-link ${active === "my_project" ? "active" : ""
                  }`}
                to="/create-podcast"
                style={
                  location.pathname === "/create-podcast"
                    ? { color: "#ff949f" }
                    : { color: "#fff" }
                }
                onClick={resetData}
              >
                Create Podcast
              </Link>
            </li>
          )}

          <li className="nav-item nav-item-2">
            <Link
              className={`nav-link ${active === "my_project" ? "active" : ""}`}
              to="/my-podcast"
              style={
                location.pathname === "/my-podcast"
                  ? { color: "#ff949f" }
                  : { color: "#fff" }
              }
            >
              My Podcast
            </Link>
          </li>

          <li className="nav-item nav-item-2">
            <Link
              className={`nav-link ${active === "integration" ? "active" : ""}`}
              to="/integration"
              style={
                location.pathname === "/integration"
                  ? { color: "#ff949f" }
                  : { color: "#fff" }
              }
            >
              Integration
            </Link>
          </li>

          {/* <li className="nav-item nav-item-4">
                  <Link className="nav-link text-link" to="/tutorials">Tutorials</Link>
                </li>
                <li className="nav-item nav-item-5">
                  <Link className="nav-link text-link" to="/support"> Support</Link>
                </li> */}
          <Profile />
        </ul>
      </div>
    </>
  );
}

NavSection.propTypes = {
  activeTab: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(NavSection);
