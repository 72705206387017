import {
  GET_TEMPLATE,
  CLEAR_TEMPLATE_VIDEO,
  FETCH_ALL_PROJECT,
  FETCH_MERGED_VIDEOS,
  SET_DROPDOWN_DATA,
  UPDATED_PROJECT_STATUS,
} from "../actions/Types";

const initialState = {
  loading: true,
  allTemplate: [],
  allProject: [],
  allMergedVideo: [],
  dropdownData: "all",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TEMPLATE:
      return {
        ...state,
        loading: false,
        allTemplate: payload,
      };
    case FETCH_ALL_PROJECT:
      return {
        ...state,
        loading: false,
        allProject: payload,
      };

    case UPDATED_PROJECT_STATUS:
      let index = state.allProject.findIndex(({ id }) => id === payload.id);
      // let dt = JSON.parse(payload);

      if (index !== -1) {
        state.allProject[index].is_active = payload.is_active;
        state.allProject[index].output_video = payload.output_video;
        state.allProject[index].thumbnail_img = payload.thumbnail_img;
      }

      // state.allProject.captions[dt.index].content = dt.data;
      return {
        ...state,
      };

    case SET_DROPDOWN_DATA:
      return {
        ...state,
        loading: false,
        dropdownData: payload,
      };
    case FETCH_MERGED_VIDEOS:
      return {
        ...state,
        loading: false,
        allMergedVideo: payload,
      };
    case CLEAR_TEMPLATE_VIDEO:
      return initialState;
    default:
      return state;
  }
}
