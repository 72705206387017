import axios from "axios";
import { baseURL } from "../global/global";
import {
  FETCH_SUPPORT_ARTICLE,
  FETCH_SUPPORT_VIDEO,
  GET_ARTICLE_CONTENT,
  GET_ONE_ARTICLE,
} from "./Types";

import { setLoader, removeLoader } from "../actions/loader";

// Fetch Support Article
export const fetchSupportArticle = () => async (dispatch) => {
  // dispatch(setLoader());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ secret_key: "34923h9uy9834h92y393009i30" });

  try {
    const res = await axios.post(`${baseURL}api/fetch-articles`, body, config);

    if (res.data.status === true) {
      dispatch(removeLoader());
      dispatch({
        type: FETCH_SUPPORT_ARTICLE,
        payload: res.data.data.map((value) => ({
          ...value,
          items: value.items.map((item, index) => ({
            ...item,
            id: value.id + index,
          })),
        })),
      });
    }
  } catch (err) {
    dispatch(removeLoader());
    console.log(err.response);
  }
};

// Fetch Support Article
export const fetchSupportVideo = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ secret_key: "34923h9uy9834h92y393009i30" });

  try {
    const res = await axios.post(`${baseURL}api/fetch-videos`, body, config);

    if (res.data.status === true) {
      dispatch({
        type: FETCH_SUPPORT_VIDEO,
        payload: res.data.data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
};

// Get One Article by category
export const getArticleCategory = (data) => (dispatch) => {
  dispatch({
    type: GET_ONE_ARTICLE,
    payload: data,
  });
};

// Get Article Content
export const getArticleContent = (data) => (dispatch) => {
  dispatch({
    type: GET_ARTICLE_CONTENT,
    payload: data,
  });
};
